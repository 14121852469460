import { Popover } from '@headlessui/react';
import {
  CloudArrowUpIcon,
  CpuChipIcon,
  PrinterIcon,
  RectangleGroupIcon,
  Square2StackIcon,
} from '@heroicons/react/24/outline';

import { Spinner } from '@/components/ui/spinner';
import {
  SUMMARY_TOOL_MENU_CSV as CSV,
  // SUMMARY_TOOL_MENU_XLS as XLS,
  // SUMMARY_TOOL_MENU_PRJ as PRJ,
  SUMMARY_TOOL_MENU_XLS_SINGLE_COL as DSC, // drawing single column
  SUMMARY_TOOL_MENU_XLS_MULTIP_COL as DMC, // drawing multiple column
  SUMMARY_TOOL_MENU_PRJ_SINGLE_COL as PSC, // project single column
  SUMMARY_TOOL_MENU_PRJ_MULTIP_COL as PMC, // project multiple column
} from '@/config';

import {
  SUMMARY_XP_FORMAT as FMT,
  EXPORT_REPORT_SCALE as ERS,
  EXPORT_REPORT_LAYOUT as ERL,
} from '../types';

export const TakeoffTopTools = ({
  downloading,
  togglePreview,
  downloadReportAs,
}: {
  downloading: boolean;
  togglePreview: boolean;
  downloadReportAs: (format: string, scale: string, layout?: string) => void;
  // previewReportToggleHandler: () => void;
}) => (
  <>
    {!downloading && <ExportDrawingTool exportTo={downloadReportAs} />}
    {downloading && (
      <div className="h-30 w-24 mx-1">
        <Spinner size="sm" className="mt-1 inline-block" />
        <span className=" ml-1 text-xs inline-block text-gray-600 align-bottom">
          Exporting...
        </span>
      </div>
    )}
    <button disabled type="button" className="btn-title-tool mr-1">
      <PrinterIcon className="size-4 text-blue-600 inline-block" />
      <span className="text-sm px-1">Print</span>
    </button>
    <button type="button" className="btn-title-tool mr-1" disabled>
      {togglePreview ? (
        <Square2StackIcon className="size-4 text-blue-600 inline-block" />
      ) : (
        <RectangleGroupIcon className="size-4 text-blue-600 inline-block" />
      )}
      <span className="text-sm px-1">
        {togglePreview ? 'Report' : 'Symbol'} View
      </span>
    </button>
    <EstimatingTool />
  </>
);

type ExportToolProps = {
  exportTo: (format: string, scale: string, layout?: string) => void;
};

export const ExportDrawingTool = ({ exportTo }: ExportToolProps) => (
  <Popover className="relative">
    <Popover.Button className="btn-title-tool mr-1">
      <CloudArrowUpIcon className="size-4 text-blue-600 inline-block" />
      <span className="text-sm px-1">Export</span>
    </Popover.Button>

    <Popover.Panel className="absolute z-500 ">
      {/* this class determine the with of menu */}
      <ul className="btn-popup-menu w-52">
        {/* <Popover.Button
          className="btn-popup-menu-item border-b border-gray-300 text-xs text-left p-2"
          onClick={() => exportTo(FMT.C, ERS.D)}
        >
          {CSV}
        </Popover.Button> */}
        {/* multiple columns for project */}
        <Popover.Button
          className="btn-popup-menu-item border-b border-gray-300 text-xs text-left p-2"
          onClick={() => exportTo(FMT.E, ERS.P, ERL.M)}
        >
          {PMC}
        </Popover.Button>
        {/* multiple columns for page */}
        <Popover.Button
          className="btn-popup-menu-item border-b border-gray-300 text-xs text-left p-2"
          onClick={() => exportTo(FMT.E, ERS.D, ERL.M)}
        >
          {DMC}
        </Popover.Button>
        {/* single column for project */}
        <Popover.Button
          className="btn-popup-menu-item border-b border-gray-300 text-xs text-left p-2"
          onClick={() => exportTo(FMT.E, ERS.P, ERL.S)}
        >
          {PSC}
        </Popover.Button>
        {/* single column for page */}
        <Popover.Button
          className="btn-popup-menu-item border-b border-gray-300 text-xs text-left p-2"
          onClick={() => exportTo(FMT.E, ERS.D, ERL.S)}
        >
          {DSC}
        </Popover.Button>
      </ul>
    </Popover.Panel>
  </Popover>
);

export const EstimatingTool = () => (
  <Popover aria-disabled className="relative">
    <Popover.Button disabled className="btn-title-tool mr-8">
      <CpuChipIcon className="size-4 text-blue-600 inline-block" />
      <span className="text-sm px-1">Estimating API</span>
    </Popover.Button>

    <Popover.Panel className="absolute z-500 ">
      <ul className="btn-popup-menu">
        <li className="btn-popup-menu-item border-b border-gray-300">
          Autodesk
        </li>
        <li className="btn-popup-menu-item border-b border-gray-300">
          Trimble
        </li>
        <li className="btn-popup-menu-item">Quotesoft</li>
      </ul>
    </Popover.Panel>
  </Popover>
);
