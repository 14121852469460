import clsx from 'clsx';

import { AnimateModal, ModalOpenProps } from '@/components/ui/modal';
import { NEW_DUCT_LENGTH_TITLE, SELECT_DUCT_SYSTEM } from '@/config';

import { useDuctLength } from '../hooks/useDuctLength';
import { useNormalView } from '../hooks/useNormalView';
import {
  ShapeSelect,
  SquareDuctInputs,
  RoundDiameterInput,
  CreateMoreSystems,
} from '../inputs';
import {
  CATALOGUE_CATEGORY,
  TKSystem,
  DUCTSHAPES as SP,
  getInputSuffixBy,
} from '../types';

type DuctLenthProps = ModalOpenProps & {
  /**
   * LENGTH_TYPE.D
   */
  selectType: string;
  systems: TKSystem[];
  unit?: string;
};

/** Create New Duct Length Modal */
export const AddDuctLength = ({
  isOpen,
  isOnStage,
  systems,
  unit,
  selectType,
  lazyCloseHandler,
}: DuctLenthProps) => {
  const {
    systemType,
    shapeType,
    diameter,
    width,
    height,
    setHeight,
    setWidth,
    setDiameter,
    setSystemType,
    shapeChangeHandler,
    applyNewElementHandler,
    cancelNewElementHandler,
  } = useDuctLength(lazyCloseHandler);

  const { isNormalView, setIsNormalView, normalView } = useNormalView();

  return (
    <AnimateModal
      isOpen={isOpen}
      isOnStage={isOnStage}
      lazyCloseHandler={lazyCloseHandler}
      title={isNormalView ? NEW_DUCT_LENGTH_TITLE : SELECT_DUCT_SYSTEM}
    >
      {/* == system field == */}
      <CreateMoreSystems
        systems={systems}
        category={CATALOGUE_CATEGORY.D}
        itemType={selectType}
        currentSystem={systemType}
        currentSystemChangeHandler={setSystemType}
        onMoreSystemSwitch={setIsNormalView}
      />
      {/* use shape radio group if necessary */}
      <>
        {isNormalView && (
          <ShapeSelect shapeType={shapeType} onChange={shapeChangeHandler} />
        )}
      </>
      {/* diameter - length - height - width */}
      <div className={normalView('flex my-2')}>
        {shapeType === SP.ROUND && (
          <RoundDiameterInput
            diameter={diameter}
            suffix={getInputSuffixBy(unit)}
            onChange={(event) => setDiameter(event.target.value)}
          />
        )}
        {shapeType === SP.SQUARE && (
          <SquareDuctInputs
            width={width}
            height={height}
            suffix={getInputSuffixBy(unit)}
            onWidthChange={(event) => setWidth(event.target.value)}
            onHeightChange={(event) => setHeight(event.target.value)}
          />
        )}
      </div>
      {/* button row */}
      <div className="flex gap-x-10 justify-between mx-0 mt-6">
        {/* close modal */}
        <button
          className="btn-red-border color-red font-semibold flex-1 red-shadow"
          onClick={cancelNewElementHandler}
        >
          Cancel
        </button>
        {/* save button */}
        <button
          className={clsx(
            'btn-green flex-1 leading-5 text-base bg-green shadow-md',
          )}
          onClick={applyNewElementHandler}
        >
          Apply
        </button>
      </div>
    </AnimateModal>
  );
};
