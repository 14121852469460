export * from './olmap';
export * from './labels';

/**
 * @date 2024/06/14
 */
export const LOGROCKET_APP_ID = 'k96uuh/taksoai';

export const PROJECT_SETTING_COUNTRY = 'CA'; // or US

// ====== routes config section ======
export const TOKEN_UNAVAILABLE_FORWARD = '/auth/login';
export const SIGN_UP_COMPLETE_FORWARD = '/auth/sign-up';
export const CONFIRM_EMAIL_FORWARD = '/auth/confirm';
export const CONFIRM_USER_INVITATION = '/auth/confirm_invitation';
export const TERMS_POLICY_FORWARD = '/auth/user-agreement-policy';
export const FORGOT_PASSWORD_FORWARD = '/auth/forgot-password';
export const RESET_PASSWORD_FORWARD = '/auth/reset-password';
export const COOKIE_POLICY_FORWARD = '/auth/cookie-policy';
export const PRIVACY_POLICY_FORWARD = '/auth/privacy-policy';

export const LOGIN_SUCCESS_ROUTE = '/home';
export const PROFILE_ROUTE = '/home/profile';
export const COMPANY_ADMIN_ROUTE = '/home/admin-profile';
export const USER_PROFILE_ROUTE = '/home/user-profile';
export const USER_DASHBOARD_ROUTE = '/home/dashboard';
export const ADMIN_DASHBOARD_URL = 'https://taksoai-dashboard.vercel.app';
// ====== end of routes config ======

/**
 * NOTE: local config object
 */
const mEnv = import.meta.env;
// console.log(mEnv);

export const appVersion = `${mEnv.VITE_APP_NAME}@${mEnv.VITE_APP_VERSION}`;

export const PRODUCT_RELEASE_VERSION = `TaksoAI v${mEnv.VITE_APP_VERSION} @2024`;

/**
 * WebSocket Server URL
 *
 * @date 2024/02/20
 */
export const WS_URL = mEnv.VITE_APP_WS_URL as string;

/**
 * Auth module Base URL, just a domain set from environment file(assigned in Vercel)
 */
export const API_URL = mEnv.VITE_APP_API_URL as string;

/**
 * development env: /v1
 * production env in master branch: /prod
 */
export const API_CTX = mEnv.VITE_APP_API_CTX as string;
export const APP_DEBUG = mEnv.VITE_APP_DEBUG_MODE as string;
export const APP_DOMAIN = mEnv.VITE_APP_DOMAIN as string;
export const APP_ENABLE_SENTRY = mEnv.VITE_APP_ENABLE_SENTRY as string;
export const RENDERING_ENHANCE = mEnv.VITE_APP_REN_ENHANCE as string;
export const APP_MEM_TRACING = mEnv.VITE_APP_MEM_TRACING as string;

/**
 * NOTE: this(`NODE_ENV`) is build parameter from building command line:
 */
export const isVercelEnv = import.meta.env.PROD;
export const isProdEnv = API_CTX === '/prod';
export const isDebugMode = APP_DEBUG === 'true';
export const isRenderingEnhanceMode = RENDERING_ENHANCE === 'true';
export const isUsingSentry = APP_ENABLE_SENTRY === 'true';
export const isUsingMemTracing = APP_MEM_TRACING === 'true';

/**
 * `Project` feature(module) API path
 */
export const TK_API_PROJECTS = `${API_CTX}/projects`;
export const TK_API_ITEMS = `${API_CTX}/items`;
export const TK_API_PROPERTIES = `${API_CTX}/properties`;
export const zoomifyBaseURL = `${API_URL}${API_CTX}/`;
export const TK_USER_ME = `${API_CTX}/users/me`;
export const STATES_OF_COUNTRY = `${API_CTX}/countries/${PROJECT_SETTING_COUNTRY}/states`;
export const STATES_OF_NORTHAMERICA = `${API_CTX}/states`;
export const CONSTRUCTION_SECTORS = `${API_CTX}/construction-sectors`;
export const PROJECT_TYPES = `${API_CTX}/project-types`;
export const PROJECT_STAGES = `${API_CTX}/project-stages`;

export const useScreenWidth =
  process.env.VITE_APP_ADAPT_SCREEN_WIDTH === 'true';

export const JWT_SECRET = '123456' as string;
export const PROJECT_COUNT_ONE_PAGE = 20;
export const PROJECTS_GALLERY_SLIDES = 15;
export const TABS_OPEN_LIMIT = 5;
export const MAX_SINGLE_UPLOAD = 6;
export const INPUT_LENGTH_LIMIT = 36;
export const INPUT_LENGTH_SHORT = 12;

export const SLIDE_SHOW_COUNTS = 4;

/**
 * - 0.037, 100 / 45 * 60, 100% completed in 45 mins
 * - 0.04, 100 / 40 * 60, 100% completed in 40 mins
 */
export const JOB_AUTO_PROGRESS_TICK = 0.037;
/**
 * scheduled auto-saving in seconds
 */
export const AUTO_SAVING_AFTER = 10;

export const TOKEN_EXPIRY_LENGTH = 24 * 3600 * 1000; // in miliseconds

/**
 * axios timeout length, in miliseconds
 * @date 2024/04/17
 */
export const NETWORK_TIMETOUT_LIMIT = 60 * 1000;

/**
 * pdf file extraction checking interval by miliseconds
 */
export const PDF_FILE_EXTRACTION_CHECKING_INTERVAL = 10 * 1000; // in miliseconds
