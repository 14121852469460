import * as _ from 'lodash-es';
import { useState } from 'react';

import { AnimateModal, ModalOpenProps } from '@/components/ui/modal';
import { Spinner } from '@/components/ui/spinner';
import { DRAWING_SUMMARY } from '@/config';

import { exportDrawingReport, exportProjectReport } from '../api';
import { TakeoffTopTools } from '../components';
import { TakeoffStats } from '../components/summary-left-stats';
import {
  TakeoffSubGroup,
  TakeoffCategoryGroup,
} from '../components/summary-sub-group';
import { useSymbolStatistics } from '../hooks/useSymbolStatistics';
import {
  useSymbolSubTotal,
  useRemoteSummaryReport,
} from '../hooks/useSymbolSummary';
import { useTableScrollHandler } from '../hooks/useTableScrollHandler';
import {
  BPSymbol,
  SymbolEditParams,
  SUMMARY_XP_FORMAT as FMT,
  EXPORT_REPORT_SCALE as ERS,
} from '../types';

type SummaryModalProps = {
  symbols: BPSymbol[];
  context: SymbolEditParams;
} & ModalOpenProps;

/**
 * Drawing symbols overview report
 */
export const SummarySubTotal = ({
  isOpen,
  isOnStage,
  lazyCloseHandler,
  symbols,
  context,
}: SummaryModalProps) => {
  const [downloading, setDownloading] = useState(false);
  const [togglePreview, setTogglePreview] = useState(true);

  // ====== main logic with hooks ======

  const { subTotalGroups } = useSymbolSubTotal(symbols || []);
  // Monitor table scrolling to fix group header!
  useTableScrollHandler(subTotalGroups, !togglePreview);

  const {
    fittingCount,
    lengthCount,
    equipmentCount,
    completionDegree,
    inCompletionDegree,
  } = useSymbolStatistics(symbols || []);

  const { projectId, fileId, pageId, jobId, projectName } = context;
  const { symbolGroups, loading } = useRemoteSummaryReport(
    projectId,
    fileId,
    pageId,
    jobId,
  );

  // console.log(symbolGroups);

  // ====== end of hooks ======

  const suffix: { [P in FMT]: string } = {
    EXCEL: 'xlsx',
    CSV: 'csv',
    PDF: 'pdf',
    PROJECT: 'xlsx', // @2024/11/06
    xls: 'xlsx', // @2024/01/23
  };
  const downloadReportAs = async (
    format: string,
    scale: string,
    layout?: string,
  ) => {
    setDownloading(true);
    const isProjectScale = scale === ERS.P;
    // console.log(`downloading ${format} file ...`);
    const response = isProjectScale
      ? await exportProjectReport(context, layout)
      : await exportDrawingReport(context, format, layout);
    const url = window.URL.createObjectURL(response);
    const link = document.createElement('a');
    const optionalLayout = layout ? `_${layout}` : '';
    const singlePageFile = `${context.fileName}_${context.pageName}${optionalLayout}`;
    const finalReportName = isProjectScale
      ? _.snakeCase(projectName) + optionalLayout
      : singlePageFile;
    link.href = url;
    // link.setAttribute('href', url);
    link.download = `${finalReportName}.${suffix[format as FMT]}`;
    // link.setAttribute('download', `${reportFullName}.${suffix[format]}`);
    document.body.appendChild(link);
    link.click();
    setTimeout(() => setDownloading(false));
  };

  const previewReportToggleHandler = () => {
    // FIXME: stop toggling this view, it produce `memory leak`!!!
    // @2024/12/18
    // setTogglePreview(!togglePreview);
  };

  return (
    <AnimateModal
      isOpen={isOpen}
      isOnStage={isOnStage}
      lazyCloseHandler={lazyCloseHandler}
      title={DRAWING_SUMMARY}
      containerClass="full-screen-summary"
      tools={
        <TakeoffTopTools
          downloading={downloading}
          togglePreview={togglePreview}
          downloadReportAs={downloadReportAs}
        />
      }
    >
      <div className="flex size-full py-2">
        {/* left column */}
        <TakeoffStats
          symbols={symbols}
          fittingCount={fittingCount}
          lengthCount={lengthCount}
          equipmentCount={equipmentCount}
          completionDegree={completionDegree}
          inCompletionDegree={inCompletionDegree}
        />
        {/* right column */}
        {/* Add additional pb-8 to reveal last row of symbol groupd regarding issue: #610 - 2024/07/10 */}
        <div className="right-panel-table w-full summary-column p-2 border-t border-gray-300 pb-8">
          {/* === BACKEND GENERATED REPORT */}
          {togglePreview &&
            symbolGroups.map((row, index) => (
              <TakeoffCategoryGroup key={index} row={row} />
            ))}
          {/* === INTERACTIVE SYMBOLS REPORT === */}
          {!togglePreview &&
            subTotalGroups.map((group, index) => (
              <TakeoffSubGroup
                key={index}
                group={group}
                lazyCloseHandler={lazyCloseHandler}
              />
            ))}
          {loading && (
            <div className="h-30 w-36 mx-1">
              <Spinner size="sm" className="mt-1 inline-block" />
              <span className=" ml-1 text-xs inline-block text-gray-600 align-bottom">
                loading reqport...
              </span>
            </div>
          )}
        </div>
      </div>
    </AnimateModal>
  );
};
