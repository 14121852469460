import * as _ from 'lodash-es';
import { useState, useEffect, ChangeEvent, useCallback, useRef } from 'react';

import { kebabCase } from '@/utils';

import { InputRendererProps } from '../inputs';
import { getSymbolDetailBy } from '../state';
import { iconFinder, shapeFinder, findFieldsByType } from '../store';
import {
  FittingEntity,
  TKEvent,
  DUCTSHAPES as SP,
  CATALOGUE_CATEGORY as FT,
  CATALOGUE_TYPE,
  GenericObject,
  OMIT_FROM_ROUND,
  OMIT_FROM_SQUARE,
  ductFieldsOmmitedBy,
  LENGTH_FIELD,
  LENGTH_STR_FIELD,
} from '../types';
import {
  getDimensionBy,
  setDimensionBy,
  getSystemBy,
} from '../types/dimension-setting';

/**
 * Add Duct Fitting
 * @param selectType
 * @param lazyCloseHandler
 * @param itemId
 * @returns
 */
export const useDuctModal = (
  selectType: string, // ELBOW ...
  lazyCloseHandler: () => void,
  itemId?: string, // feature id, only available in symbol correction!
) => {
  const lastSystem = getSystemBy(selectType) || '';
  const [currentSystem, setCurrentSystem] = useState<string>(lastSystem); // system belonged
  const [currentType, setCurrentType] = useState(selectType); // symbol type in capitalized
  const [ductFields, setDuctFields] = useState(findFieldsByType(selectType)); // raw fields from config
  const [useShape, setUseShape] = useState(shapeFinder(selectType));
  // shape is complex to decide, so move to an effect to check!
  const [shapeType, setShapeType] = useState('');
  const [fieldProps, setFieldProps] = useState<{ [key: string]: string }>({}); // kebab case field pairs

  // == cache a used shape type ==
  const shapeRef = useRef('');

  /** FIXME: ensure the value is in lower case
   * @2023/06/22
   * Check history first
   * @2025/01/16
   */
  const safeShapeType = shapeRef.current || shapeType;

  const ductFieldsFilterBy = useCallback(
    (shape: string) => {
      if (shape === SP.ROUND) {
        return _.difference(ductFields, OMIT_FROM_ROUND);
        // return ['diameter_1'];
      }
      if (shape === SP.SQUARE) {
        return _.difference(ductFields, OMIT_FROM_SQUARE);
      }
      return ductFields; // shapeType is not in use
    },
    [ductFields],
  );

  /**
   * figure out `shapeType`, round by default:
   */
  useEffect(() => {
    useShape && setShapeType(SP.ROUND);
  }, [useShape]);

  /**
   * TODO: anyway to separate this function out of this hook? - 2023/07/13
   *
   * figure out the properties needed for each field input component
   *
   * @param fieldName each field name
   * @returns normal object
   */
  const inputFieldRenderer = (fieldName: string): InputRendererProps => {
    const kebabField = kebabCase(fieldName);
    const fieldValueChangeHandler = (key: string, value: string) => {
      // console.log({ key });
      const changedProps = { ...fieldProps, [key]: value };
      setFieldProps(changedProps);
    };
    const isLengthField = fieldName === LENGTH_FIELD;
    const isLengthInString = fieldName === LENGTH_STR_FIELD;
    const inputProps: InputRendererProps = {
      ...fieldProps,
      disabled: isLengthInString,
      fieldName, // `DefaultInput` need this
      fieldLabel: isLengthField ? 'LENGTH(in pixels)' : '',
      fieldValue: fieldProps[kebabField], // `DefaultInput` need this
      fieldHandler: fieldValueChangeHandler,
      blurHandler: () => null, // FIXME: placeholder for now - 2023/11/08
    };

    return inputProps;
  };

  const shapeChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setShapeType(event.target.value);
    shapeRef.current = event.target.value;
  };

  /**
   * figure out the attributes displayed on the right side panel
   * @returns `{}`
   */
  const createFeatureEntity = (id?: string): FittingEntity => {
    const icon = iconFinder(currentType);
    // consider shape field
    const fieldsWithShape = useShape
      ? { shape: shapeType, ...fieldProps }
      : fieldProps;
    // ignore unused attributes
    const fieldsOmmited = ductFieldsOmmitedBy(shapeType);
    const pickedProperties: {
      [key: string]: string;
    } = _.omit(fieldsWithShape, fieldsOmmited);

    const detail: FittingEntity = {
      category: FT.D,
      itemType: CATALOGUE_TYPE.F,
      system: currentSystem,
      entityType: currentType || 'UNDEFINED',
      icon,
      toolType: FT.D,
      ...pickedProperties,
    };

    if (id) {
      detail.id = id;
    }

    return detail;
  };

  /**
   * doing 2 jobs:
   *
   * 1. notify map to draw new element with attributes object
   * 2. cache input values to reuse
   */
  const applyNewElementHandler = () => {
    const detail = createFeatureEntity();
    // notify map to start drawing ...
    const event = new CustomEvent(TKEvent.NEWELEMENT, { detail });
    document.dispatchEvent(event);

    // cache shape field
    const fieldsWithShape = useShape
      ? { shape: shapeType, ...fieldProps }
      : fieldProps;
    // cache input
    currentType && setDimensionBy(currentType, fieldsWithShape);

    // close
    lazyCloseHandler();
  };

  const ductTypeChangeHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    setCurrentType(event.target.value);
    setDuctFields(findFieldsByType(event.target.value));
    setUseShape(shapeFinder(event.target.value));
  };

  // lazy close, and dispatch cancel tool event...
  const cancelNewElementHandler = () => {
    lazyCloseHandler();
    const evt = new Event(TKEvent.CANCEL_DRAWING_TOOL);
    document.dispatchEvent(evt);
  };

  return {
    currentSystem,
    currentType,
    shapeType: safeShapeType,
    useShape,
    ductFields: ductFieldsFilterBy(safeShapeType),
    fieldProps, // exposed at 2024/02/28
    setCurrentSystem,
    ductTypeChangeHandler,
    inputFieldRenderer,
    applyNewElementHandler,
    shapeChangeHandler,
    cancelNewElementHandler,
  };
};
