/**
 * Created at 2023/08/04 for v0.3 refactoring
 * from map base
 *
 * Removed majority of feature restyling function to `featureRender`,
 * leave style object functions and other simple function here!
 * @2023/09/12
 */

import { Feature } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { FeatureLike } from 'ol/Feature';
import {
  Point,
  Polygon,
  Geometry,
  MultiPoint,
  LineString,
  Circle,
  MultiLineString,
} from 'ol/geom';
import {
  Style,
  Fill,
  Stroke,
  Circle as CircleStyle,
  Text,
  RegularShape,
} from 'ol/style';
import { StyleLike } from 'ol/style/Style';
import { TextPlacement } from 'ol/style/Text';

import { error } from '@/utils/logger';

import {
  FEATURESTATE as FST,
  SymbolType,
  LENGTH_TYPE,
  SYMBOL_REVISION as REV,
  DETECTION_MODE as DTM,
} from '../types';
import { calculateCenter } from '../types/math';

import {
  FITTINGTYPE,
  FITTINGSIZE,
  FITTINGVER,
  DUCK_VERSION_NUMBER as DUCK,
  DEFAULT_LINE_COLOR,
  DEFAULT_DIAMOND_RADIUS,
  DEFAULT_EQUIPMENT_FONT_SIZE as DEF,
  DEFAULT_TRIANGLE_RADIUS,
  SYMBOL_PALETTE as PAL,
  COMMON_STROKE_WIDTH as CSW,
} from './config';

export type SymbolPalette = {
  fillColor: string;
  strokeColor: string;
  strokeWidth: number;
  /** for fill color */
  transparency: number;
};

type VersionPalette = { [key: string]: { strokeColor: string } };

export const lengthColors: { [key: string]: string } = {
  [LENGTH_TYPE.D]: PAL.UAL, // orange for duct length
  [LENGTH_TYPE.P]: PAL.UAL, // red for pipe length
  ['DEFAULT']: DEFAULT_LINE_COLOR, // gray
};

const strokeParency = {
  strokeWidth: 1,
  transparency: 0.002,
};

/**
 * v1 varaint palette, for missing info fittings(DUCT|PIPE)
 */
const variantPaletteV1: VersionPalette = {
  ['DUCT']: {
    strokeColor: PAL.MSD,
  },
  ['PIPE']: {
    strokeColor: PAL.MSP,
  },
  [LENGTH_TYPE.D]: {
    strokeColor: PAL.MSD,
  },
  [LENGTH_TYPE.P]: {
    strokeColor: PAL.MSP,
  },
};

/**
 * v2 varaint palette, for completed fittings(DUCT|PIPE)
 */
const variantPaletteV2: VersionPalette = {
  ['DUCT']: {
    strokeColor: PAL.CUD, // pink: #F1948A
  },
  ['PIPE']: {
    strokeColor: PAL.CUP, // burgundy, 酒红色 #943126
  },
  [LENGTH_TYPE.D]: {
    strokeColor: PAL.CUD,
  },
  [LENGTH_TYPE.P]: {
    strokeColor: PAL.CUP,
  },
};

/**
 * User added dots and lines
 */
const variantPaletteM: VersionPalette = {
  [DTM.M]: {
    strokeColor: PAL.UAF,
  },
};

/**
 * DESIGN RULES:
 * a variant for `DUCT` and `PIPE` after version workflow, v1, v2 applied!
 *
 * @date 2024/03/19
 */
export const variantForState: { [version: string]: VersionPalette } = {
  [REV.B]: variantPaletteV1,
  [REV.C]: variantPaletteV2,
  [REV.M]: variantPaletteM,
};

/**
 * Version could be color name to act as a feature theme
 *
 * @date 2023/11/16
 */
export const versionPalette: { [key: string]: SymbolPalette } = {
  // duck feature version
  [DUCK]: {
    fillColor: '#000000',
    strokeColor: DEFAULT_LINE_COLOR,
    strokeWidth: 2,
    transparency: 0,
  },
  [REV.A]: {
    fillColor: '#ed1941',
    strokeColor: '#aa2116',
    strokeWidth: 2,
    transparency: 1,
  },
  [REV.B]: {
    fillColor: '#000000', // black
    strokeColor: '#0000FF', //  blue
    strokeWidth: CSW,
    transparency: 0.001, // FIXME: remove box shading - 2024/03/04
  },
  [REV.C]: {
    fillColor: '#FCAF17', // orange
    strokeColor: PAL.CUD, // pink for completed symbol
    strokeWidth: CSW,
    transparency: 0.002,
  },
  [REV.D]: {
    fillColor: '#03C04A', // light green
    strokeColor: PAL.UCF, // grey green from simon feedback - @2024/03/06
    strokeWidth: CSW,
    transparency: 0.002,
  },
  [REV.M]: {
    fillColor: '#000000', // black
    strokeColor: '#0000FF', //  blue, will be overrided by variant palette
    strokeWidth: CSW,
    transparency: 0.002,
  },
  [REV.BL]: {
    fillColor: '#041BC5', // blue
    strokeColor: '#041BC5', // blue
    ...strokeParency,
  },
  [REV.G]: {
    fillColor: '#479C4C', // green
    strokeColor: '#479C4C', // green
    ...strokeParency,
  },
  [REV.O]: {
    fillColor: '#ED662C', // orange
    strokeColor: '#ED662C', // orange
    ...strokeParency,
  },
  // === for newly added symbols ===
  [REV.P]: {
    fillColor: '#B241BC', // purple
    strokeColor: '#B241BC', // purple
    ...strokeParency,
  },
  [REV.R]: {
    fillColor: '#D32E39', // red
    strokeColor: '#D32E39', // red
    ...strokeParency,
  },
  [REV.Y]: {
    fillColor: '#F9E54D', // yellow
    strokeColor: '#F9E54D', // yellow
    ...strokeParency,
  },
};

export const getDucPalette = () => versionPalette[DUCK];

// set different border color for fittings ...
const strokeColors: { [key: string]: string } = {
  Duct: 'blue',
  Pipe: 'red',
};

/**
 * Check current feature if active or not
 * @param feature
 * @returns true, false
 */
export const isActive = (feature: Feature | FeatureLike) => {
  return !!feature.get(FST.A);
};

export const isDisabled = (feature: Feature | FeatureLike) => {
  const disabled = feature.get(FST.D);
  if (disabled) return true;
  return false;
};

export const isDuckFeature = (feature: Feature | FeatureLike) => {
  const version = feature.get(FITTINGVER);
  return version === DUCK;
};

export const isPointFeature = (feature: Feature | FeatureLike) => {
  const geometry = feature.getGeometry();
  return isPoint(geometry as Geometry);
};

export const isPolygon = (geometry: Geometry | undefined) => {
  return geometry instanceof Polygon;
};

export const isLineString = (geometry: Geometry | undefined) => {
  return geometry instanceof LineString;
};

export const isMultiLineString = (geometry: Geometry | undefined) => {
  return geometry instanceof MultiLineString;
};

export const isCircle = (geometry: Geometry | undefined) => {
  return geometry instanceof Circle;
};

export const isPoint = (geometry: Geometry | undefined) => {
  return geometry instanceof Point;
};

export const isEquipmentFeature = (feature: Feature | FeatureLike) => {
  const geometry = feature.getGeometry();
  const symbolType = feature.get(FITTINGTYPE) as SymbolType;
  const isEquipment = symbolType === SymbolType.EQUIPMENT;
  return isPoint(geometry as Geometry) && isEquipment;
};

/**
 * @deprecated 2024/03/19
 * @param symbol
 * @returns
 */
export const getStrokeColor4Feature = (symbol: Feature) => {
  const detail = symbol.get('detail') || {};
  const category = detail['category']; // symbol category
  return strokeColors[category] || 'blue';
};

/**
 * For `activeFeature` use
 * @param feature
 * @returns
 */
export const getActivePointStyle = (feature: Feature): Style | undefined => {
  const symbolType = feature.get(FITTINGTYPE) as SymbolType;
  const needRichFormat = symbolType === SymbolType.EQUIPMENT;
  const label = feature.get(FITTINGSIZE) as string;

  // FIXME: this could be null, no idea in which case?
  // @2024/07/23
  const origStyle = feature.getStyle() as Style;
  if (!origStyle) {
    error(`## got null orig style!`);
    return undefined;
  }
  const origText = (origStyle as Style).getText();
  // triangle or diamond
  if (!origText) {
    // console.log(`>> to recreate red image style !`);
    // FIXME: use original image and modify its color!
    // to fixe issue https://taksoai.atlassian.net/browse/TAK-541
    // @2024/04/02
    // FIXME: Weird ! GOT ERROR in this issue:
    // https://taksoai.atlassian.net/browse/TAK-558
    // @2024/04/04
    const image = origStyle.getImage() as RegularShape;
    if (!image) {
      return origStyle;
    }
    const fill = new Fill({ color: 'rgba(255, 0, 0, 0.6)' });
    const stroke = new Stroke({ color: 'red', width: 2 });
    image.setFill(fill);
    image.setStroke(stroke);
    return new Style({ image });
  }
  const origFont = origText.getFont();
  const text = basicTextStyle(label, 12, true, 'point', needRichFormat, true);
  // FIXME: keep using original font
  // @2023/08/04
  text.setFont(origFont);
  const redCenter = new CircleStyle({
    radius: 6, // smaller for dot circle feature @2022/11/30
    fill: new Fill({
      color: '#ff3333', // red center
    }),
  });
  return new Style({ text, image: redCenter });
};

/**
 * build a text object for feature style
 *
 * @param label text
 * @param size font size
 * @param italic
 * @param placement
 * @param isRichFormat
 * @param isActive for highlight style
 * @param color for text color, green by defaut
 *
 * @returns
 */
export const basicTextStyle = (
  label?: string,
  size?: number,
  italic?: boolean,
  placement: TextPlacement = 'point',
  isRichFormat = false,
  isActive = false,
  color = 'green',
): Text => {
  const fontSize = size || DEF;
  const options = {
    font: `bold ${italic ? 'italic' : ''} ${fontSize}px "Open Sans", "Arial Unicode MS", "sans-serif"`,
    placement, // line, point
    fill: new Fill({
      color,
    }),
    offsetX: 0,
    offsetY: -8,
    overflow: true, // allowing text overlap, and display more
    stroke: new Stroke({
      color: 'white',
      width: 1,
    }),
    text: label || 'MOCK',
  };
  // more format to create a box,
  const richOpts = {
    fill: new Fill({
      color: 'coral',
    }),
    // override the default options
    placement: 'point' as TextPlacement,
    // available while placement is point only
    backgroundStroke: new Stroke({
      color: isActive ? 'green' : 'orange',
      width: 1,
    }),
    // available while placement is point only
    backgroundFill: new Fill({
      color: [168, 50, 153, 0.1],
    }),
    padding: [1, 1, 1, 1],
    // NOTE: keep text algin left to make text looks stable
    // @2024/04/29
    textAlign: 'center',
    textBaseline: 'middle',
  };
  const endOptions = isRichFormat ? { ...options, ...richOpts } : options;
  return new Text(endOptions);
};

export const basicPolygonStyle = (
  active = false,
  strokeColor = 'blue',
  strokeWidth = 1,
): Style => {
  return new Style({
    /**
     * NOTE: Remember to keep this `geometry` property!
     * It will allow the rectangle symbol to resize in a rectanglar shape!
     * Once removed this part then it caused a bug:
     * https://taksoai.atlassian.net/browse/TAK-501
     *
     * @param feature
     * @returns
     */
    geometry: function (feature) {
      const modifyGeometry = feature.get('modifyGeometry');
      return modifyGeometry ? modifyGeometry.geometry : feature.getGeometry();
    },
    fill: new Fill({
      color: 'rgba(0, 0, 0, 0.01)', // transparent black
    }),
    stroke: new Stroke({
      color: active ? '#3F99F7' : strokeColor, // sky blue line
      width: strokeWidth,
    }),
  });
};

/**
 * NOT IN USE
 * @deprecated
 * @param center
 * @returns
 */
export const basicCircleImage = (center: Coordinate): Style => {
  return new Style({
    image: new CircleStyle({
      radius: 4,
      fill: new Fill({
        color: '#FFFFFF', // white
      }),
    }),
    geometry: new Point(center),
  });
};

export const basicCircleStyle = (
  strokeColor = 'green', // orange
  strokeWidth = 2,
): Style => {
  return new Style({
    stroke: new Stroke({
      color: strokeColor,
      width: strokeWidth,
    }),
    fill: new Fill({
      color: 'rgba(255, 194, 14, 0.01)', // orange
    }),
  });
};

export const basicTriangleStyle = (
  strokeColor = 'blue',
  strokeWidth = 2,
  radius = DEFAULT_TRIANGLE_RADIUS,
): Style => {
  const stroke = new Stroke({ color: strokeColor, width: strokeWidth });
  const fill = new Fill({ color: 'rgba(255, 0, 0, 0.01)' });
  return new Style({
    image: new RegularShape({
      fill: fill,
      stroke: stroke,
      points: 3,
      radius,
      rotation: Math.PI / 4,
      angle: 0,
    }),
  });
};

export const basicDiamonStyle = (
  strokeColor = 'blue',
  strokeWidth = 2,
  radius = DEFAULT_DIAMOND_RADIUS,
): Style => {
  const stroke = new Stroke({ color: strokeColor, width: strokeWidth });
  const fill = new Fill({ color: 'rgba(255, 0, 0, 0.01)' });
  return new Style({
    image: new RegularShape({
      fill: fill,
      stroke: stroke,
      points: 4,
      radius,
      angle: 0,
    }),
  });
};

/**
 * Reset linestring or polygon style after selected
 * @param feature linestring or polygon
 * @returns
 */
export const modifyStateFunction = (feature: FeatureLike): Style[] => {
  const basicStyle = basicPolygonStyle();
  basicStyle.setFill(
    new Fill({
      color: 'rgba(0, 255, 0, 0.5)', // translucent green
    }),
  );
  basicStyle.setStroke(
    new Stroke({
      color: 'green',
      width: 3,
    }),
  );
  const isCircle = feature.getGeometry() instanceof Circle;
  const circleStyle = basicCircleStyle('orange', 2);
  const styles = [isCircle ? circleStyle : basicStyle];
  const modifyGeometry = feature.get('modifyGeometry');
  const geometry = modifyGeometry
    ? modifyGeometry.geometry
    : feature.getGeometry();
  const result = calculateCenter(geometry);
  const center = result.center;
  if (center) {
    styles.push(
      new Style({
        geometry: new Point(center),
        image: new CircleStyle({
          radius: 4, // smaller for dot circle feature @2022/11/30
          fill: new Fill({
            color: '#ff3333', // red center
          }),
        }),
      }),
    );
    const coordinates = result.coordinates;
    if (coordinates) {
      const minRadius = result.minRadius;
      const sqDistances = result.sqDistances;
      const rsq = minRadius * minRadius;
      const points = coordinates.filter(function (coordinate, index) {
        return sqDistances[index] > rsq;
      });
      styles.push(
        new Style({
          geometry: new MultiPoint(points),
          image: new CircleStyle({
            radius: 6, // bigger for convenience of handling
            fill: new Fill({
              color: '#ffcc33', // yellow handler
            }),
          }),
        }),
      );
    }
  }
  return styles;
};

/**
 * Create line style with palette color `PAL.UAL`
 * @date 2024/03/21
 *
 * @param color color string
 * @param stroke stroke size
 * @returns
 */
export const basiceLengthStyle = (
  color: string = PAL.UAL,
  stroke?: number,
): Style => {
  return new Style({
    geometry: function (feature) {
      const modifyGeometry = feature.get('modifyGeometry');
      return modifyGeometry ? modifyGeometry.geometry : feature.getGeometry();
    },
    stroke: new Stroke({
      color,
      width: stroke || 3,
    }),
  });
};

/**
 * Update text style for feature from `resetFeatureStyle/featureUpdater`
 *
 * @param style
 * @param label
 * @param fontSize
 * @param placement
 * @param isRichFormat
 */
export const updateStyleText = (
  style: Style,
  label: string,
  fontSize?: number,
  placement: TextPlacement = 'line',
  isRichFormat = false,
) => {
  const text = basicTextStyle(
    label || '(:',
    fontSize,
    false,
    placement,
    isRichFormat,
  );
  style.setText(text);
  return text;
};

/**
 * Set eraser style by stroke width
 *
 * @date 2024/04/03
 * @param width dynamic stroke width decided by zoom level
 * @returns
 */
export const eraseStyle = (width: number) => {
  return new Style({
    fill: new Fill({
      color: '#FFFFFF', // white
    }),
    stroke: new Stroke({
      color: '#FFFFFF', // white
      width,
    }),
  });
};

/**
 * NOT IN USE
 *
 * @deprecated
 * @returns
 */
export const basiceLineStyle = (): Style => {
  return new Style({
    geometry: function (feature) {
      const modifyGeometry = feature.get('modifyGeometry');
      return modifyGeometry ? modifyGeometry.geometry : feature.getGeometry();
    },
    stroke: new Stroke({
      color: 'blue', // sky blue line
      width: 2,
    }),
  });
};

/**
 * Gray blue sketch line style
 *
 * @date 2024/05/14
 * @returns
 */
export const minimalStyle = (): Style => {
  const stroke = new Stroke({ color: '#444C87', width: 1 });
  return new Style({ stroke });
};
