/**
 * List all the system names and color config
 * @2023/02/08
 */

// import { removeSlashes } from '@/utils';

import { FITTING_TYPE } from '.';

export interface Option {
  readonly label: string;
  readonly value: string;
}

export const createCreatableSelectOption = (label: string, value?: string) => ({
  label,
  value: value || label.toLowerCase().replace(/\W/g, ''),
});

export const defaultSystems = [
  createCreatableSelectOption('One'),
  createCreatableSelectOption('Two'),
  createCreatableSelectOption('Three'),
];

export const createTKSystem = (label: string, category: string): TKSystem => ({
  short: label.toUpperCase(),
  fullName: label,
  color: '',
  category,
});

export const createMoreSystem = (category: string) => ({
  short: '*',
  color: '',
  category,
  fullName: 'Show more...',
});

/**
 * system definition of drawing
 */
export type TKSystem = {
  short: string;
  fullName: string;
  color: string;
  category: string;
};

/** HVAC Duct systems  */
