import { FittingDimension, GO } from '.';

/**
 * 18 type of DUCT fittings
 * @2022/12/13
 */
export enum FITTING_DUCT {
  BALANCING_DAMPER = 'BALANCING_DAMPER',
  MANUAL_BALANCING_DAMPER = 'MANUAL_BALANCING_DAMPER',
  CENTER_LINE_REDUCER = 'CENTER_LINE_REDUCER',
  ECCENTRIC_REDUCER = 'ECCENTRIC_REDUCER',
  ELBOW = 'ELBOW',
  EXHAUST_DUCT = 'EXHAUST_DUCT',
  FIRE_DAMPER = 'FIRE_DAMPER',
  METER_DUCT = 'METER_DUCT',
  MOTORIZED_DAMPER = 'MOTORIZED_DAMPER',
  MOTORIZED_COMBINATION_FIRE_AND_SMOKE_DAMPER = 'MOTORIZED_COMBINATION_FIRE_AND_SMOKE_DAMPER',
  RETURN_DUCT = 'RETURN_DUCT',
  RETURN_DUCT_DOWN = 'RETURN_DUCT_DOWN',
  ROUND_DUCT_DOWN = 'ROUND_DUCT_DOWN',
  ROUND_DUCT_UP = 'ROUND_DUCT_UP',
  SUPPLY_AIR_DIFFUSER = 'SUPPLY_AIR_DIFFUSER',
  SUPPLY_OR_OUTSIDE_AIR_DUCT = 'SUPPLY_OR_OUTSIDE_AIR_DUCT',
  PERIMETER_DIFFUSER = 'PERIMETER_DIFFUSER',
  Y_JUNCTION = 'Y_JUNCTION',
  STRAIGHT_LENGTH_DUCT = 'STRAIGHT_LENGTH_DUCT',
}

/**
 * Handling dimension cache during the symbol addtion and corrections
 */
type FDCONFIG = { [key: string]: FittingDimension };

const defaultValues = {
  angle: '90',
  shape: 'round',
};

/**
 * TODO: move this to `dimensionStore` ...
 * runtime inputs cache for different fittings
 */
export const dimensions: FDCONFIG = {
  // runtime to add it...
  [FITTING_DUCT.BALANCING_DAMPER]: defaultValues,
  [FITTING_DUCT.ELBOW]: defaultValues,
  [FITTING_DUCT.CENTER_LINE_REDUCER]: defaultValues,
  [FITTING_DUCT.ECCENTRIC_REDUCER]: defaultValues,
  [FITTING_DUCT.Y_JUNCTION]: defaultValues,
};

/**
 * cache fitting details
 * @deprecated TODO: in favor of using `redux-store` next year
 * @param type fitting type
 * @param dimension fitting details
 */
export const setDimensionBy = (type: string, dimension: FittingDimension) => {
  dimensions[type] = dimension;
};

/**
 * default & cached dimension values
 * @deprecated TODO: in favor of using `redux-store` next year
 * @param type symbol type
 * @returns
 */
export const getDimensionBy = (type: string) => dimensions[type] || {};

const systemByEntity: GO = {};

/**
 * Cache system value for each fitting type to initial system select default value
 *
 * FIXME: Regardless the `type`, save it with latest system - @2024/01/24
 *
 * @param type entity type like `ELBOW` ...
 * @param system
 */
export const setSystemBy = (type: string, system: string) => {
  systemByEntity['default'] = system;
};

/**
 * FIXME: Regardless the `type`, save it with latest system - @2024/01/24
 * @deprecated TODO: move this to `dimensionStore` ...
 * @param type
 * @returns
 */
export const getSystemBy = (type: string): string => {
  if (!type) return '';
  return systemByEntity['default'] || '';
};

/**
 * Get last used dimension value, if no cached one found, use hard-coded `dimensions` setting
 *
 * @param ductType
 * @returns
 */
export const lastShapeFor = (ductType: string | undefined) => {
  if (!ductType) return '';
  const values = getDimensionBy(ductType.toUpperCase());
  return (values['shape'] as string) || '';
};
