/**
 * State derive functions
 * @date 2024/04/18
 */
import * as _ from 'lodash-es';

import { ALL_DEFAULT_SYTM, EMPTY_OPTION_LABEL } from '@/config';

import { findItemByType } from '../store';
import {
  BPSymbol,
  TKSystem,
  SYMBOL_MODE,
  SymbolCategoryOptions as CATEGORIES,
  FittingMeta,
  CATALOGUE_CATEGORY,
  DEFAULT_ALL_SHORT,
  DEFAULT_EMPTIY_OPT,
  TKProjectFileInfo,
  TKProjectPlus,
  TKProject,
} from '../types';

import { calculateFloatCompletion } from './builder';
import {
  defaultItem,
  SymbolsGroupState,
  SymbolState,
  SymbolsReviewState,
  SymbolsFilteringPayload,
} from './common';
import { pickingSymbolJustBy, pickupSymbolsByFilter } from './filters';

const emptyFileInfo: TKProjectFileInfo = {
  pid: '',
  loading: true,
  file_count: 0,
  first_file: null,
  last_file: null,
};
export const projectPlusMaker = (p: TKProject): TKProjectPlus => ({
  ...p,
  ...emptyFileInfo,
  // FIXME: use project info to get files @2023/05/03
  file_count: p.project_total_blueprints,
});

export const allSystemOption: TKSystem = {
  short: DEFAULT_ALL_SHORT,
  fullName: ALL_DEFAULT_SYTM,
  color: 'black',
  category: CATALOGUE_CATEGORY.D,
};

export const emptySystemOption: TKSystem = {
  short: DEFAULT_EMPTIY_OPT,
  fullName: EMPTY_OPTION_LABEL,
  color: 'black',
  category: CATALOGUE_CATEGORY.D,
};

// ============ selectors for review panel filtering ================

/**
 * Filter out sub-items from selected filter
 * @param allItems all the item types of current drawing
 * @param filter filter type from drop-down select formatted: category|type
 * @returns sub-items
 */
const getItemTypesByGroupFilter = (
  allItems: FittingMeta[],
  filter: string,
): FittingMeta[] => {
  return allItems
    .map((it) => (`${it.category}|${it.rawType}` === filter ? it : null))
    .filter((it) => !!it);
};

/**
 * Update `items` and `totalCompletion` by symbols/category.
 * === DO NOT SET `selectedItem` in this selector, cos we need to use it! ===
 *
 * FIXME: re-filter symbols by selected item type!
 * FIX ISSUE: https://taksoai.atlassian.net/browse/TAK-709
 * @2024/09/26
 *
 * FIXME: Also, we need to consider item_type(selectedItem) at each time row-change after cell input
 * @2024/11/13
 *
 * @param state
 * @param payload
 * @returns
 */
export const selectItemTypesFromSymbols = (
  state: SymbolsReviewState,
  payload: SymbolsFilteringPayload,
): SymbolsReviewState => {
  const { symbols } = payload;
  if (!symbols) return state;

  // symbols will refresh after changed the row and moved to the next row!
  // need to consider previously selected sub-type: selectedItem
  const { category, selectedItem } = state;
  const symbolItemMap = new Set<string>();
  symbols.forEach((smb) => symbolItemMap.add(smb.class));
  // convet to array
  const symbolItemTypes = [...symbolItemMap];
  // cache all the item type definitions for later `category` checking!
  const allItems = symbolItemTypes
    .map((type) => findItemByType(type))
    .filter((it) => it !== undefined) as FittingMeta[]; // remove those undefined
  const itemsInDrawing = getItemTypesByGroupFilter(allItems, category);
  const items = [defaultItem, ...itemsInDrawing];
  const totalCompletion = calculateFloatCompletion(symbols);
  // Consider last selected sub-type: selectedItem
  // @2024/11/13
  const itemType = selectedItem?.type;
  // FIXME: do not use `ALL` in sub item type
  // @2024/11/19
  const useItemType = itemType !== defaultItem.type && itemType;
  const filteredSymbols = useItemType
    ? pickingSymbolJustBy(itemType, symbols)
    : pickupSymbolsByFilter(category, symbols);

  // return state;
  return {
    ...state,
    symbols, // cache all the symbols for filtering in other actions!
    items,
    allItems,
    filteredSymbols,
    totalCompletion,
  };
};

export const selectAllSystems = (
  state: SymbolsReviewState,
  payload: SymbolsFilteringPayload,
): SymbolsReviewState => {
  const { systems } = payload;
  if (!systems) return state;

  const { category } = state;
  const fittingFilter = (sys: TKSystem) => sys.category === category;
  const withinGroup = systems.filter(fittingFilter);
  const groupSystems = [allSystemOption, emptySystemOption, ...withinGroup];
  return { ...state, systems, groupSystems };
};

export const selectItemsFromCategory = (
  state: SymbolsReviewState,
  payload: SymbolsFilteringPayload,
): SymbolsReviewState => {
  const { category } = payload;
  if (!category) return state;

  const { symbols, systems, allItems } = state;
  const itemsInDrawing = getItemTypesByGroupFilter(allItems, category);
  const items = [defaultItem, ...itemsInDrawing];
  const fittingFilter = (sys: TKSystem) => sys.category === category;
  const withinGroup = systems.filter(fittingFilter);
  const groupSystems = [allSystemOption, emptySystemOption, ...withinGroup];
  const filteredSymbols = pickupSymbolsByFilter(category, symbols);
  return {
    ...state,
    category,
    items,
    groupSystems,
    selectedItem: defaultItem,
    filteredSymbols,
  };
};
/**
 * change item type, such as `ELBOW`
 * @param state
 * @param payload
 * @returns
 */
export const selectItemType = (
  state: SymbolsReviewState,
  payload: SymbolsFilteringPayload,
): SymbolsReviewState => {
  const { itemType } = payload;
  if (!itemType) return state;

  const { category, symbols } = state;
  const selectedItem = state.items.find(
    (item) => item.type === itemType,
  ) as FittingMeta;
  const defaultSelected = itemType === defaultItem.type;
  const filteredSymbols = defaultSelected
    ? pickupSymbolsByFilter(category, symbols)
    : pickingSymbolJustBy(itemType, symbols);
  return {
    ...state,
    selectedItem,
    filteredSymbols,
  };
};

export const selectSystemType = (
  state: SymbolsReviewState,
  payload: SymbolsFilteringPayload,
): SymbolsReviewState => {
  const { systemType } = payload;
  if (!systemType) return state;

  return {
    ...state,
    selectedSystem: systemType,
  };
};

/**
 * FIXME: item-typs under group missing...because of `category` changed to filter like value!
 * @date 2024/11/23
 * @param state
 * @param payload
 * @returns
 */
export const selectCategoryAndItemType = (
  state: SymbolsReviewState,
  payload: SymbolsFilteringPayload,
): SymbolsReviewState => {
  const { category, itemType } = payload;
  if (!category || !itemType) return state;

  const { symbols, systems, allItems } = state;
  const symbolItemMap = new Set<string>();
  symbols.forEach((smb) => symbolItemMap.add(smb.class));
  const itemsInDrawing = getItemTypesByGroupFilter(allItems, category);
  const items = [defaultItem, ...itemsInDrawing];
  const selectedItem = itemsInDrawing.find(
    (item) => item.type === itemType,
  ) as FittingMeta;
  const fittingFilter = (sys: TKSystem) => sys.category === category;
  const withinGroup = systems.filter(fittingFilter);
  const groupSystems = [allSystemOption, emptySystemOption, ...withinGroup];
  const filteredSymbols = pickingSymbolJustBy(itemType, symbols);
  return {
    ...state,
    items,
    category, // NOTE: Need to reset main group - 2024/11/07
    selectedItem,
    groupSystems,
    filteredSymbols,
  };
};

/**
 * Synchronize `topFilter` from top tool to review panel state,
 * to reveal `category` & `item type`.
 * @param state
 * @param payload
 * @returns
 */
export const findCategoryAndItemType = (
  state: SymbolsReviewState,
  payload: SymbolsFilteringPayload,
): SymbolsReviewState => {
  const { topFilter } = payload;
  const { allItems, symbols } = state;
  if (!topFilter) return state;

  // 1. compose a category-filter from review panel
  const categoryOption = CATEGORIES.find(
    (ct) => `${ct.category}|${ct.type}` === topFilter,
  );
  if (categoryOption) {
    const itemsInDrawing = getItemTypesByGroupFilter(allItems, topFilter);
    const items = [defaultItem, ...itemsInDrawing];
    const filteredSymbols = pickupSymbolsByFilter(topFilter as string, symbols);
    return {
      ...state,
      category: topFilter as string,
      items,
      selectedItem: defaultItem,
      filteredSymbols,
    };
  }
  // 2. FIXME: otherwise should be `item type`
  // @2024/11/18
  // console.log(`## could not sync top filter: ${topFilter} to review panel...`);
  // console.log(`>>> not found topFilter: ${topFilter} in categories!`);
  // console.log(`>>> search it from allItems...`);
  // const itemDef = allItems.find((it) => it.type === topFilter);
  // if (itemDef) {
  //   const { category, type } = itemDef;
  //   const itemsInDrawing = allItems
  //     .map((it) => (it.category === category ? it : null))
  //     .filter((it) => !!it) as FittingMeta[];
  //   const items = [defaultItem, ...itemsInDrawing];
  //   const filteredSymbols = pickingSymbolJustBy(type, symbols);
  //   return {
  //     ...state,
  //     category,
  //     items,
  //     selectedItem: itemDef,
  //     filteredSymbols,
  //   };
  // }
  return state;
};

export const selectAllCompletedSymbols = (
  state: SymbolsReviewState,
  payload: SymbolsFilteringPayload,
): SymbolsReviewState => {
  const { showAllCompleted } = payload;
  const patch = {
    allCompleted: !!showAllCompleted,
    allSymbolsDisplay: state.allSymbolsDisplay,
  };
  if (!showAllCompleted) {
    patch.allSymbolsDisplay = false;
  }
  return {
    ...state,
    ...patch,
  };
};

export const selectUserConfirmedSymbols = (
  state: SymbolsReviewState,
  payload: SymbolsFilteringPayload,
): SymbolsReviewState => {
  const { showUserConfirmed } = payload;
  const patch = {
    userCompleted: !!showUserConfirmed,
    allSymbolsDisplay: state.allSymbolsDisplay,
  };
  if (!showUserConfirmed) {
    patch.allSymbolsDisplay = false;
  }
  return {
    ...state,
    ...patch,
  };
};

export const selectColumnToSort = (
  state: SymbolsReviewState,
  payload: SymbolsFilteringPayload,
): SymbolsReviewState => {
  const { fieldToSort } = payload;
  if (!fieldToSort) return state;
  const reOrder = state.order === 0 ? 1 : -state.order;
  return {
    ...state,
    fieldToSort,
    order: reOrder,
  };
};

export const selectSymbolToDelete = (
  state: SymbolsReviewState,
  payload: SymbolsFilteringPayload,
): SymbolsReviewState => {
  const { symbolId } = payload;
  if (!symbolId) return state;

  const { filteredSymbols } = state;
  const remaining = filteredSymbols.filter((fs) => fs.id !== symbolId);
  return {
    ...state,
    filteredSymbols: remaining,
  };
};

/**
 * Do 2 things:
 * - show completed
 * - show confirmed
 *
 * @date 2024/12/03
 * @param state
 * @param payload
 * @returns
 */
export const selectAllSymbolShowInDrawing = (
  state: SymbolsReviewState,
  payload: SymbolsFilteringPayload,
): SymbolsReviewState => {
  const { displayAll } = payload;
  return {
    ...state,
    userCompleted: !!displayAll,
    allCompleted: !!displayAll,
    allSymbolsDisplay: !!displayAll,
  };
};

export const selectSymbolToConfirmed = (
  state: SymbolsReviewState,
  payload: SymbolsFilteringPayload,
): SymbolsReviewState => {
  const { symbolId } = payload;
  if (!symbolId) return state;

  const { symbols, filteredSymbols } = state;

  // FIXME: check if already confirmed - @2024/12/17
  const filter = (smb: BPSymbol) => smb.id === symbolId;
  const symbolPossibleConfirmed = symbols.find(filter);
  if (symbolPossibleConfirmed?.confirmed) return state; // no change!

  const updatedSymbols = symbols.map((smb) =>
    smb.id === symbolId ? { ...smb, confirmed: true } : smb,
  );
  const confirmedPatched = filteredSymbols.map((smb) =>
    smb.id === symbolId ? { ...smb, confirmed: true } : smb,
  );
  return {
    ...state,
    symbols: updatedSymbols,
    filteredSymbols: confirmedPatched,
  };
};

// ============= selectors for drawing symbols rendering ==============

export const selectLoadSymbols = (
  state: SymbolsGroupState,
  payload: SymbolState,
): SymbolsGroupState => {
  // FIXME: use `reload` flag to mute spinner - @2024/03/26
  const { reload, page } = payload;
  // new state:
  const pageStateUpdated = {
    ...state,
    loading: true,
    reload,
    currentPage: page,
  };
  return pageStateUpdated;
};

export const selectExitLoadSymbols = (
  state: SymbolsGroupState,
  payload: SymbolState,
): SymbolsGroupState => {
  return { ...state, loading: false, reload: false };
};

export const selectInitSymbolStore = (
  state: SymbolsGroupState,
  payload: SymbolState,
): SymbolsGroupState => {
  const pageDetections = state.pageDetections;
  pageDetections[state.currentPage] = payload.symbols || [];
  return {
    ...state,
    pageDetections,
  };
};

export const selectDisposeSymbols = (
  state: SymbolsGroupState,
  payload: SymbolState,
): SymbolsGroupState => {
  const pageDetections = state.pageDetections;
  delete pageDetections[payload.page]; // remove it from memory
  return {
    ...state,
    pageDetections,
  };
};

export const selectAddSymbols = (
  state: SymbolsGroupState,
  payload: SymbolState,
): SymbolsGroupState => {
  const { page, symbol } = payload;
  if (!symbol) return state;
  const symbolsForPage = state.pageDetections[page];
  const pageDetections = {
    ...state.pageDetections,
    [page]: [...symbolsForPage, symbol as BPSymbol],
  };
  return {
    ...state,
    pageDetections,
  };
};

export const selectClearSymbols = (
  state: SymbolsGroupState,
  payload: SymbolState,
): SymbolsGroupState => {
  return {
    ...state,
    pageDetections: {},
  };
};

export const selectUpdateSymbols = (
  state: SymbolsGroupState,
  payload: SymbolState,
): SymbolsGroupState => {
  // update one symbol or multiple symbols
  const { page, symbol, symbols } = payload;
  const symbolsForPage = state.pageDetections[page];
  const symbolsToUpdate = symbol ? [symbol as BPSymbol] : symbols || [];
  const mergedSymbols = symbolsForPage.map((smbl) => {
    const filter = (stu: BPSymbol) => {
      // FIXME: `undefined` symbol check from issue:
      // https://taksoai.atlassian.net/browse/TAK-680
      // @2024/08/08
      if (!stu || !smbl) return false;
      return stu.id === smbl.id;
    };
    const checkIsChanged = symbolsToUpdate.find(filter);
    return checkIsChanged || smbl;
  });
  const pageDetections = {
    ...state.pageDetections,
    [page]: mergedSymbols,
  };
  return {
    ...state,
    pageDetections,
  };
};

export const selectDeleteSymbols = (
  state: SymbolsGroupState,
  payload: SymbolState,
): SymbolsGroupState => {
  // symbol/symbols to delete
  const { page, symbol, symbols } = payload;
  const symbolsForPage = state.pageDetections[page];
  const generateStateBySymbols = (smbs: BPSymbol[]) => {
    const pageDetections = { ...state.pageDetections, [page]: smbs };
    return {
      ...state,
      pageDetections,
    };
  };
  // case 1: delete one symbol by its id
  if (symbol) {
    const targetSymbol = symbolsForPage.find(
      (smb) => smb.id === (symbol as string),
    );
    if (!targetSymbol) return state; // no change!
    const symbolMode = targetSymbol.mode;
    // to delete NEW symbol, just remove it from symbols store
    if (symbolMode === SYMBOL_MODE.NEW) {
      const remaining = symbolsForPage.filter(
        // FIXME: EXCLUDE the NEW symbol to be deleted @2023/06/26
        (smb) => smb.id !== (symbol as string),
      );
      return generateStateBySymbols(remaining);
    }
    // CASE 2: to delete a previously existed symbol, just mark it `DEL`
    const oneMarkDeletion = symbolsForPage.map((smb) => {
      if (smb.id === symbol) smb.mode = SYMBOL_MODE.DEL;
      return smb;
    });
    return generateStateBySymbols(oneMarkDeletion);
  }
  // case 3: delete multiple symbols
  const deletedSymbolIds = symbols?.map((smb) => smb.id);
  const markMultipleDeletion = symbolsForPage.map((smb) => {
    const isDeleted = deletedSymbolIds?.includes(smb.id);
    if (isDeleted) smb.mode = SYMBOL_MODE.DEL;
    return smb;
  });
  return generateStateBySymbols(markMultipleDeletion);
};
