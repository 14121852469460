import clsx from 'clsx';

import { AnimateModal, ModalOpenProps } from '@/components/ui/modal';
import { NEW_PIPE_MODAL_TITLE, SELECT_PIPE_SYSTEM } from '@/config';

import { useNormalView } from '../hooks/useNormalView';
import { usePipeModal } from '../hooks/usePipeModal';
import { PureDiameterInput, CreateMoreSystems } from '../inputs';
import { getPipeFittings } from '../store';
import {
  FittingEntity,
  CATALOGUE_CATEGORY,
  TKSystem,
  getInputSuffixBy,
} from '../types';

type PipeModalProps = ModalOpenProps & {
  selectType: string;
  systems: TKSystem[];
  newTypeApplyHandler?: (fe: FittingEntity) => void;
  unit?: string;
};

/** Draw New Pipe Fitting Modal */
export const AddPipeFitting = ({
  isOpen,
  isOnStage,
  selectType,
  systems,
  unit,
  lazyCloseHandler,
}: PipeModalProps) => {
  const { isNormalView, setIsNormalView, normalView } = useNormalView();
  // main logic for this modal:
  const upm = usePipeModal(selectType, lazyCloseHandler);
  const { currentType, setCurrentSystem } = upm;

  return (
    <AnimateModal
      isOpen={isOpen}
      isOnStage={isOnStage}
      lazyCloseHandler={lazyCloseHandler}
      title={isNormalView ? NEW_PIPE_MODAL_TITLE : SELECT_PIPE_SYSTEM}
    >
      {/* == system select == */}
      <CreateMoreSystems
        systems={systems}
        category={CATALOGUE_CATEGORY.P}
        itemType={selectType}
        currentSystem={upm.currentSystem}
        currentSystemChangeHandler={setCurrentSystem}
        onMoreSystemSwitch={setIsNormalView}
      />
      {/* select duct type */}
      <label className={normalView('color-primary text-sm block mb-4 mt-0')}>
        FITTING TYPE
        <select
          name="province"
          className="block w-full px-2 py-1 mt-1 text-sm frame-input"
          value={currentType}
          onChange={(event) => upm.setCurrentType(event.target.value)}
        >
          {getPipeFittings().map((pipe) => (
            <option key={pipe.type} value={pipe.type}>
              {pipe.label}
            </option>
          ))}
        </select>
      </label>
      {/* diameter - length - height - width */}
      <div className={normalView('flex gap-2 my-2')}>
        {upm.useDiameterField && (
          <PureDiameterInput
            diameter={upm.diameter}
            suffix={getInputSuffixBy(unit)}
            onChange={(event) => upm.setDiameter(event.target.value)}
          />
        )}
        {/* specifically for `PIPE_REDUCER` - 2025/01/16 */}
        {upm.useDiameter2Field && (
          <PureDiameterInput
            isSecond
            diameter={upm.diameter2}
            suffix={getInputSuffixBy(unit)}
            onChange={(event) => upm.setDiameter2(event.target.value)}
          />
        )}
      </div>
      {/* button row */}
      <div className={normalView('flex gap-x-10 justify-between mx-0 mt-4')}>
        {/* close modal */}
        <button
          className="btn-red-border color-red font-semibold flex-1 red-shadow"
          onClick={() => lazyCloseHandler()}
        >
          Cancel
        </button>
        {/* save button */}
        <button
          className={clsx(
            'btn-green flex-1 leading-5 text-base bg-green shadow-md',
          )}
          onClick={upm.applyNewElementHandler}
        >
          Apply
        </button>
      </div>
    </AnimateModal>
  );
};
