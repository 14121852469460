import ClickAwayHandler from '@/components/ui/wrapper/click-away';

import { useSymbolFilters } from '../hooks/useSymbolFilters';
import { GenericObject, FILTERS, FILTER_LABELS as LABELS } from '../types';

import { MultilevelMenu } from './multi-level-menu';

// selected filer name displayed at dropdown button!
const filterTypes: GenericObject = {
  [FILTERS.DEFAULT]: LABELS.DEFAULT,
  [FILTERS.ALL]: LABELS.ALL,
  [FILTERS.DUCTS]: LABELS.DUCTS,
  [FILTERS.PIPES]: LABELS.PIPES,
  [FILTERS.DLENT]: LABELS.DLENT,
  [FILTERS.PLENT]: LABELS.PLENT,
  [FILTERS.PLMBS]: LABELS.PLMBS,
  [FILTERS.MXDUCT]: LABELS.MXDUCT,
  [FILTERS.MXPIPE]: LABELS.MXPIPE,
  [FILTERS.EQMT]: LABELS.EQMT,
  [FILTERS.OFST]: LABELS.OFST,
  [FILTERS.MXPIBO]: LABELS.MXPIBO,
};

/**
 * Use shared symbol filter
 */
export const FilterSymbolSection = ({
  currentFilter,
  onFilterChange,
}: {
  currentFilter: string;
  onFilterChange: (filter: string) => void;
}) => {
  const filters = useSymbolFilters(currentFilter, onFilterChange);
  const filterLabelFull = filterTypes[currentFilter] || currentFilter;
  return (
    <ClickAwayHandler
      classNames="tool-section-md mr-4 border-r border-gray hidden xl:flex"
      handleClickAway={filters.closeDropdownMenuHandler}
    >
      <div className="left-part flex-1">
        <h3 className="text-tiny color-blue-gray uppercase">Filter</h3>
        <div className="filter-options flex gap-3 mt-3 relative">
          <button
            type="button"
            title={filterLabelFull}
            className="block select frame-input-select color-primary icon-select-short w-28"
            onClick={filters.menuDropDownOpenHandler}
          >
            {filterLabelFull.substring(0, 12)}
          </button>
          {/* dynamically popped up drop down menu, hidden by default */}
          <MultilevelMenu handleFilterChange={filters.handleFilterChange} />
        </div>
      </div>
      <div className="right-part flex-1">
        {/* size text display toggling */}
        <label
          htmlFor="toggle-size"
          className="block hover:text-primary-over"
          title="Cover current symbols"
        >
          <input
            id="toggle-size"
            type="checkbox"
            className="mr-1 select-none inline-block align-middle"
            checked={filters.sizeHiddenChecked}
            onChange={filters.toggleSizeDisplay}
          />
          <span className="inline-block text-xs leading-7 h-7 select-none">
            Hide Size Text
          </span>
        </label>
        {/* inverse filtering */}
        <label
          htmlFor="inverse-filter"
          className="block hover:text-primary-over"
          title="Cover current symbols"
        >
          <input
            id="inverse-filter"
            type="checkbox"
            className="mr-1 select-none inline-block align-middle"
            checked={filters.inverseChecked}
            onChange={filters.reverseCheckHandler}
          />
          <span className="inline-block text-xs leading-7 h-7 select-none">
            Inverse Filtering
          </span>
        </label>
      </div>
    </ClickAwayHandler>
  );
};
