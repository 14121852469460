import { ChangeEventHandler } from 'react';

import diameterIcon from '@/assets/icons/diameter_icon.svg';
// import helpIcon from '@/assets/icons/ic_outline-help.svg';
import heightIcon from '@/assets/icons/height_icon.svg';
import widthIcon from '@/assets/icons/width_icon.svg';

import { DUCTSHAPEOPTS as DTSP, TKSystem } from '../types';

export type RoundInputProps = {
  suffix: string;
  diameter: string;
  isSecond?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
};

export const PureDiameterInput = ({
  diameter,
  isSecond,
  suffix,
  onChange,
}: RoundInputProps) => (
  <label className="flex-1 color-primary relative">
    <img src={diameterIcon} alt="diameter-icon" className="inline-block" />
    <span className=" absolute left-4 top-1">{isSecond ? '2' : '1'}</span>
    <input
      type="number"
      name="diameter"
      step=".01"
      placeholder=""
      className="inline-block frame-input prefix-icon ml-3 text-sm"
      value={diameter}
      onChange={onChange}
    />
    <span className="text-xs absolute right-0 top-0 text-gray-500 bg-gray-100 p-1.5 frame-suffix">
      {suffix}
    </span>
  </label>
);

export const RoundDiameterInput = ({
  diameter,
  suffix,
  onChange,
}: RoundInputProps) => (
  <>
    <label className="flex-1 color-primary relative">
      <img src={diameterIcon} alt="diameter-icon" className="inline-block" />
      <input
        type="number"
        name="diameter"
        step=".01"
        placeholder=""
        className="inline-block frame-input prefix-icon ml-3 text-sm"
        value={diameter}
        onChange={onChange}
      />
      <span className="text-xs absolute right-0 top-0 text-gray-500 bg-gray-100 p-1.5 frame-suffix">
        {suffix}
      </span>
    </label>
    {/* this make input half size of parent */}
    <div className="ignore-me flex-1" />
  </>
);

export type SystemSelectProps = {
  systemType: string;
  systems: TKSystem[];
  onChange: ChangeEventHandler<HTMLSelectElement>;
};

export const SystemSelect = ({
  systemType,
  onChange,
  systems,
}: SystemSelectProps) => (
  <label className="color-primary text-sm block mb-4 mt-8">
    DUCT SYSTEM
    <select
      name="systemType"
      className="block w-full px-2 py-1 mt-1 text-sm frame-input"
      value={systemType}
      onChange={onChange}
    >
      <option value="" disabled>
        Select system
      </option>
      {systems.map((ds) => (
        <option key={ds.short} value={ds.short}>
          {ds.fullName}({ds.short})
        </option>
      ))}
    </select>
  </label>
);

export type ShapeSelectProps = {
  shapeType: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
};

export const ShapeSelect = ({ shapeType, onChange }: ShapeSelectProps) => (
  <div className="flex my-2">
    {DTSP.map((opt) => (
      <label key={opt.value} className="label-block flex-1 color-primary">
        {opt.label}
        <input
          type="radio"
          name="shape"
          value={opt.value}
          checked={shapeType === opt.value}
          onChange={onChange}
        />
        <span className="checkmark"></span>
      </label>
    ))}
  </div>
);

export type SquareInputProps = {
  width: string;
  onWidthChange: ChangeEventHandler<HTMLInputElement>;
  height: string;
  onHeightChange: ChangeEventHandler<HTMLInputElement>;
  suffix: string;
};

export const SquareDuctInputs = ({
  width,
  onWidthChange,
  height,
  onHeightChange,
  suffix,
}: SquareInputProps) => (
  <>
    <label className="flex-1 color-primary relative">
      <img src={widthIcon} alt="height-icon" className="inline-block size-5" />
      <input
        type="number"
        step=".01"
        name="width_1"
        placeholder=""
        className="inline-block frame-input prefix-icon ml-3 text-sm"
        value={width}
        onChange={onWidthChange}
      />
      <span className="text-xs absolute right-0 top-0 text-gray-500 bg-gray-100 p-1.5 frame-suffix">
        {suffix}
      </span>
    </label>
    <label className="flex-1 color-primary relative">
      <img src={heightIcon} alt="height-icon" className="inline-block size-5" />
      <input
        type="number"
        step=".01"
        name="height_1"
        placeholder=""
        className="inline-block frame-input prefix-icon ml-3 text-sm"
        value={height}
        onChange={onHeightChange}
      />
      <span className="text-xs absolute right-0 top-0 text-gray-500 bg-gray-100 p-1.5 frame-suffix">
        {suffix}
      </span>
    </label>
  </>
);
