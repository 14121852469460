/**
 * Add Pipe fitting modal
 *
 * @date 2023/12/20
 */
import { useState, useEffect } from 'react';

import { getPipeFittings, findFieldsByType } from '../store';
import {
  FittingEntity,
  TKEvent,
  FIXTUREATTRS as FX,
  CATALOGUE_CATEGORY as FT,
  CATALOGUE_TYPE,
} from '../types';
import {
  getDimensionBy,
  setDimensionBy,
  getSystemBy,
} from '../types/dimension-setting';

/**
 * Add Pipe Fitting
 * @param selectType
 * @param lazyCloseHandler
 * @returns
 */
export const usePipeModal = (
  selectType: string,
  lazyCloseHandler: () => void,
) => {
  const lastSystem = getSystemBy(selectType) || '';
  const [currentSystem, setCurrentSystem] = useState<string>(lastSystem); // system belonged
  const [currentType, setCurrentType] = useState(selectType); // element type
  const [diameter, setDiameter] = useState('');
  // ! possibly have 2nd diameter for `PIPE_REDUCER`
  // @2025/01/16
  const [diameter2, setDiameter2] = useState('');

  const fieldsForPipe = findFieldsByType(currentType);
  const useDiameterField = fieldsForPipe.includes(FX.D1);
  const useDiameter2Field = fieldsForPipe.includes(FX.D2);

  const createFeatureEntity = (): FittingEntity => {
    const iconForType = getPipeFittings().find(
      (pipe) => pipe.type === currentType,
    );
    const icon = iconForType?.icon as string;
    const detail: FittingEntity = {
      category: FT.P,
      system: currentSystem,
      entityType: currentType,
      itemType: CATALOGUE_TYPE.F,
      icon,
      toolType: FT.P,
    };
    if (useDiameterField) {
      detail.diameter_1 = diameter;
    }
    if (useDiameter2Field) {
      detail.diameter_2 = diameter2;
    }
    return detail;
  };

  const applyNewElementHandler = () => {
    lazyCloseHandler();
    const detail = createFeatureEntity();
    // lazy notification to map to add new element!
    setTimeout(() => {
      const event = new CustomEvent(TKEvent.NEWELEMENT, { detail });
      document.dispatchEvent(event);
    }, 200);

    setDimensionBy(selectType, { diameter });
  };

  useEffect(() => {
    const fields = getDimensionBy(currentType);
    // console.log(fields);
    setDiameter(fields.diameter || '');
  }, [currentType]);

  return {
    currentType,
    currentSystem,
    diameter,
    diameter2,
    useDiameterField,
    useDiameter2Field,
    setDiameter,
    setDiameter2,
    setCurrentType,
    setCurrentSystem,
    applyNewElementHandler,
  };
};
