import * as _ from 'lodash-es';
import { useState, useEffect } from 'react';

import { warn, log } from '@/utils/logger';

import { fetchDrawingSummary } from '../api';
import { getDuctFittings, getPipeFittings, findFieldsByType } from '../store';
import {
  BPSymbol,
  FittingMeta,
  LENGTH_TYPE as LEN,
  SubGroup,
  CatalogueSubTotalGroup as CTG,
  SymbolSummaryRow,
  LENGTH_FIELD,
  SymbolEditParams,
} from '../types';

/**
 * Fetch backend generated summary data then display into popup modal,
 * this will keep data consistent with exported report.
 *
 * @date 2023/06/09
 * @param projectId
 * @param fileId
 * @param pageId
 * @param jobId
 */
export const useRemoteSummaryReport = (
  projectId: string,
  fileId: string,
  pageId: string,
  jobId: string,
) => {
  const [loading, setLoading] = useState(false);
  const [symbolGroups, setSymbolGroups] = useState<SymbolSummaryRow[]>([]);

  useEffect(() => {
    if (!projectId || !fileId || !pageId || !jobId) return;

    const params: SymbolEditParams = {
      projectId,
      fileId,
      pageId,
      jobId,
    };
    const safeFetchSummary = async (params: SymbolEditParams) => {
      try {
        const result = await fetchDrawingSummary(params);
        const rows = result as SymbolSummaryRow[];
        const groups = rows.map((row) => ({
          ...row,
          isCategory: !row.quantity && !row.system && !row.units,
        }));
        const noDuctPipe = groups.filter(
          (group) =>
            group.item_id_group !== 'DUCT' &&
            group.item_id_group !== 'PIPE' &&
            group.item_id_group !== 'DCUT',
        );
        setSymbolGroups(noDuctPipe);
      } catch (err: any) {
        warn(err?.message);
      }
      setLoading(false);
    };
    // FIXME: fetch summary that may empty if no job items existing!
    // @2024/11/06
    safeFetchSummary(params);
    setLoading(true);
  }, [projectId, fileId, pageId, jobId]);

  return {
    loading,
    symbolGroups,
  };
};

/**
 * figure out the groups by dimension value for each symbol catalogue
 *
 * @param symbols all symbols of current drawing
 * @param ignoreRows if ignore expanded rows data, `true` for report export
 * @returns
 */
export const useSymbolSubTotal = (symbols: BPSymbol[], ignoreRows = false) => {
  const [subTotalGroups, setSubTotalGroups] = useState<CTG[]>([]);

  useEffect(() => {
    // === 1ST STEP: group symbols by type
    const catalogueGroups: { [type: string]: BPSymbol[] } = {};
    symbols.forEach((smb) => {
      // FIXME: do not consider `Length` symbols!
      // if (smb.type === SymbolType.LINE) return;

      const smbType = smb.class;
      if (!catalogueGroups[smbType]) catalogueGroups[smbType] = [];
      const groupByType = catalogueGroups[smbType];
      groupByType.push(smb);
    });
    // === 2nd STEP: compose sub-total group ===
    const subTotalGroups = Object.entries(catalogueGroups).map(
      ([type, groupSymbols]) => {
        // FIXME: no need to add `s` at the end of type!
        // https://taksoai.atlassian.net/browse/TAK-574
        const titleCapitalize = `${_.startCase(_.lowerCase(type))}`;
        // FIXME: no need to display `length_1` to user - @2023/08/01
        const noLength1 = (f: string) => f !== LENGTH_FIELD;
        const itemDefinedFields = findFieldsByType(type).filter(noLength1);
        const fieldsForDisplay = itemDefinedFields.map((f) => _.capitalize(f));
        const fieldsWithMore = [
          'Item Type',
          'Count',
          ...fieldsForDisplay,
          'System',
          // 'View',
        ];
        // === 3RD STEP: figure out sub group with fields ===
        const subGroupSymbols: { [type: string]: BPSymbol[] } = {};
        // divide this big group into small group with same field values
        groupSymbols.forEach((smb) => {
          const subGroupKey = itemDefinedFields.reduce((prev, field) => {
            return smb.detail ? `${prev}_${smb.detail[field]}` : prev;
          }, '');
          if (!subGroupSymbols[subGroupKey]) subGroupSymbols[subGroupKey] = [];
          const groupByComposedKey = subGroupSymbols[subGroupKey];
          // figure out a simple symbol
          const { thumbnail, ...simpleSymb } = smb; // thumbnail field is not useful for report
          const { detail, ...otherProps } = simpleSymb;
          const simpleDetail = { ...detail };
          delete simpleDetail.icon; // icon field is not useful for report
          // *** collect a symbol version symbol for report export ***
          groupByComposedKey.push({ ...otherProps, detail: simpleDetail });
        });

        const typeAbbre = `${_.lowerCase(type).substring(0, 16)}...`;
        const expandableGroups: SubGroup[] = Object.entries(
          subGroupSymbols,
        ).map(([key, smbs]) => {
          const summary = key.split('_');
          summary[0] = `${smbs.length}`; // count
          summary.unshift(typeAbbre); // add symbol type at start of row
          // summary.push(''); // system
          // create a group with sub-totaled summary row
          return {
            summary,
            rows: ignoreRows ? [] : smbs,
          };
        });

        return {
          count: groupSymbols.length,
          title: titleCapitalize,
          fields: fieldsWithMore, // column label
          dimension: itemDefinedFields, // fields to display
          groups: expandableGroups,
        };
      },
    );
    // console.log(`>> figure out sub total groups ...`);
    setSubTotalGroups(subTotalGroups);
  }, [symbols, ignoreRows]);

  useEffect(() => {
    // cleanup - 22024/12/18
    return () => {
      setSubTotalGroups([]);
      log(`## sub groups in takeoff reclaimed!`);
    };
  }, []);

  return {
    subTotalGroups,
  };
};

type SummaryRow = {
  /** feature id */
  id: string;
  index: string;
  cells: string[];
};

export type SymbolSummaryGroup = {
  type: string;
  count: number;
  fields: string[];
  rows: SummaryRow[];
} | null;

// ======================== @deprecated ==================================

/**
 * @deprecated
 * @param symbols
 * @returns
 */
export const useSymbolSummary = (symbols: BPSymbol[]) => {
  const [symbolGroups, setSymbolGroups] = useState<SymbolSummaryGroup[]>([]);
  const [fittingCount, setFittingCount] = useState(0);
  const [lengthCount, setLengthCount] = useState(0);

  // console.log(symbols);

  useEffect(() => {
    // 1st step: group symbols by type
    const catalogueGroups: { [type: string]: BPSymbol[] } = {};
    symbols.forEach((smb) => {
      const smbType = smb.class;
      if (!catalogueGroups[smbType]) catalogueGroups[smbType] = [];
      const groupByType = catalogueGroups[smbType];
      groupByType.push(smb);
    });
    // 2nd step: create summary group
    const ductFittings = getDuctFittings();
    const pipeFittings = getPipeFittings();
    const allFittings = [...ductFittings, ...pipeFittings];
    const summaryGroupCreator = (fm: FittingMeta): SymbolSummaryGroup => {
      const tempGroup = catalogueGroups[fm.type];
      if (!tempGroup) return null;
      const fieldsWidthSystem = fm.fields.includes('system')
        ? fm.fields
        : [...fm.fields, 'system'];
      return {
        type: fm.type,
        count: tempGroup.length,
        fields: fieldsWidthSystem,
        rows: tempGroup.map((smb) => ({
          id: smb.id as string,
          index: `#${smb.index}`,
          cells: fieldsWidthSystem.map((f) =>
            smb.detail ? smb.detail[f] : '',
          ),
        })),
      };
    };
    const fittingSymbolGroups: SymbolSummaryGroup[] = allFittings
      .map(summaryGroupCreator)
      .filter((group) => !!group);
    // update groups
    setSymbolGroups(fittingSymbolGroups);
    const fittingCounter = fittingSymbolGroups.reduce((prev, group) => {
      if (group?.type !== LEN.D && group?.type !== LEN.P) {
        return prev + (group ? group.count : 0);
      } else {
        return prev;
      }
    }, 0);
    // figure out fitting counts
    setFittingCount(fittingCounter);
    // figure out length counts
    const dLens = catalogueGroups[LEN.D];
    const pLens = catalogueGroups[LEN.P];
    const dLenCounter = dLens ? dLens.length : 0;
    const pLenCounter = pLens ? pLens.length : 0;
    // got the length total
    setLengthCount(dLenCounter + pLenCounter);
  }, [symbols]);

  const completeCount = symbols.reduce((prev, smb) => {
    const props = smb.detail;
    if (!props) return prev;
    let allHasValue = true;
    Object.values(props).forEach((v) => {
      if (!v) allHasValue = false;
    });
    if (allHasValue) return prev + 1;
    return prev;
  }, 0);

  const matureDegree = symbols.length
    ? Math.floor((100 * completeCount) / symbols.length)
    : 0;

  return {
    completeCount,
    fittingCount,
    lengthCount,
    matureDegree: `${matureDegree}%`,
    symbolGroups,
  };
};
