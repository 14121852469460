import * as _ from 'lodash-es';
import { useState, useEffect, ChangeEvent } from 'react';

import { kebabCase } from '@/utils';

import { InputRendererProps } from '../inputs';
import { iconFinder, findFieldsByType } from '../store';
import {
  FittingEntity,
  TKEvent,
  CATALOGUE_CATEGORY as CC,
  CATALOGUE_TYPE as CT,
  GenericObject,
  LENGTH_FIELD,
  LENGTH_STR_FIELD,
} from '../types';
import {
  getDimensionBy,
  setDimensionBy,
  getSystemBy,
} from '../types/dimension-setting';

export const usePlumbingModal = (
  /** selecte plumbing type */
  selectType: string,
  lazyCloseHandler: () => void,
) => {
  const lastSystem = getSystemBy(selectType) || '';
  const [currentSystem, setCurrentSystem] = useState<string>(lastSystem); // system belonged
  // const [currentType, setCurrentType] = useState(selectType); // symbol type in capitalized
  // const [plumbFields, setPlumbFields] = useState<string[]>([]); // raw fields from config
  // shape is complex to decide, so move to an effect to check!
  const [fieldProps, setFieldProps] = useState<{ [key: string]: string }>({}); // kebab case field pairs
  // switched plumbing type
  const [currentType, setCurrentType] = useState(selectType); // element type
  // computed plumb fields
  const plumbFields = findFieldsByType(currentType);

  /**
   * figure out `fieldProps` from last used symbol setting
   */
  useEffect(() => {
    if (!selectType) return;
    // use cached dimension values or symobl detail
    const values = getDimensionBy(selectType);
    const fieldPairs = _.pick(values, plumbFields) as GenericObject;
    setFieldProps(fieldPairs); // construct key/value group
    // console.log(`>>> cached values for symbol:`);
    // console.log(values);

    // *** reset system property: ***
    const { system } = values;
    if (!system) {
      // console.warn(`## no system value!`);
      return;
    }
    // FIXME: check if system has a `/` to ensure it has,
    // in order to display its value with the right option
    // @2023/06/23
    const strSystem = system as string;
    const hasNoSlash = !strSystem.includes('/');
    const withSlash = [...strSystem].join('/');
    // add '/' manually!
    if (hasNoSlash) return setCurrentSystem(withSlash);
    return setCurrentSystem(strSystem);
  }, [selectType, plumbFields]);

  /**
   * TODO: anyway to separate this function out of this hook? - 2023/07/13
   *
   * figure out the properties needed for each field input component
   *
   * @param fieldName each field name
   * @returns normal object
   */
  const inputFieldRenderer = (fieldName: string): InputRendererProps => {
    const kebabField = kebabCase(fieldName);
    const fieldValueChangeHandler = (key: string, value: string) => {
      // console.log({ key });
      const changedProps = { ...fieldProps, [key]: value };
      setFieldProps(changedProps);
    };
    const isLengthField = fieldName === LENGTH_FIELD;
    const isLengthInString = fieldName === LENGTH_STR_FIELD;
    const inputProps: InputRendererProps = {
      ...fieldProps,
      disabled: isLengthInString,
      fieldName, // `DefaultInput` need this
      fieldLabel: isLengthField ? 'LENGTH(in pixels)' : '',
      fieldValue: fieldProps[kebabField], // `DefaultInput` need this
      fieldHandler: fieldValueChangeHandler,
      blurHandler: () => null, // FIXME: placeholder for now - 2023/11/08
    };

    return inputProps;
  };

  /**
   * figure out the attributes displayed on the right side panel
   * @param type current selecte type
   * @returns `{}`
   */
  const createFeatureEntity = (type: string, id?: string): FittingEntity => {
    const icon = iconFinder(type);
    const detail: FittingEntity = {
      category: CC.PLM,
      system: currentSystem,
      entityType: type || 'UNDEFINED',
      itemType: CT.F,
      icon,
      toolType: CC.PLM,
      ...fieldProps,
    };
    // id is a must inclusion in symbol `detail`
    if (id) {
      detail.id = id;
    }

    return detail;
  };

  /**
   * doing 2 jobs:
   *
   * 1. notify map to draw new element with attributes object
   * 2. cache input values to reuse
   */
  const applyNewElementHandler = () => {
    const detail = createFeatureEntity(currentType);
    // notify map to start drawing ...
    const event = new CustomEvent(TKEvent.NEWELEMENT, { detail });
    document.dispatchEvent(event);

    // cache input
    selectType && setDimensionBy(selectType, fieldProps);

    // close
    lazyCloseHandler();
  };

  // lazy close, and dispatch cancel tool event...
  const cancelNewElementHandler = () => {
    lazyCloseHandler();
    const evt = new Event(TKEvent.CANCEL_DRAWING_TOOL);
    document.dispatchEvent(evt);
  };

  return {
    currentSystem,
    plumbFields,
    fieldProps,
    currentType,
    setCurrentType,
    setCurrentSystem,
    inputFieldRenderer,
    applyNewElementHandler,
    // shapeChangeHandler,
    cancelNewElementHandler,
  };
};
