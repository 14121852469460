/**
 * Display system by fitting type
 * @2023/02/16
 */
import clsx from 'clsx';
import { useState, useEffect } from 'react';
import { SingleValue } from 'react-select';

import { getSavedCustomSystems, saveCustomSystem } from '@/utils/storage';

import { cacheSystemsBy, getCachedSystemsBy } from '../state';
import { TKSystem } from '../types';
import { setSystemBy } from '../types/dimension-setting';
import {
  Option,
  createMoreSystem,
  createTKSystem,
  createCreatableSelectOption as createOption,
} from '../types/systems';

export const useSystemsBy = (
  systems: TKSystem[],
  category: string,
  onSystemSelect: (value: string) => void,
  /**
   * item type for system select cache
   */
  entityType: string,
  /**
   * callback to notify select view switched to grid view or not
   * @date 2024/04/25
   */
  onSystemViewToggle?: (isRawSelect: boolean) => void,
) => {
  // view switching flag
  const [isNormalView, setIsNormalView] = useState(true);

  // FIXME: some system `short` is null, need to remove it
  // @2024/03/19
  const fittingFilter = (sys: TKSystem) =>
    sys.category === category && !!sys.short;

  const systemsByCategory = systems.filter(fittingFilter);
  const top5Systems = systemsByCategory.slice(0, 5);
  const top5WithMore = [...top5Systems, createMoreSystem(category)];

  // save user selected system and place on the top of predefined list
  const cachedSystems = getCachedSystemsBy(category);
  const [pickedSystem, setPickedSystem] = useState<TKSystem[]>(cachedSystems);

  /**
   * system list displayed in dropwdown select!
   */
  const mergedSystems = [...pickedSystem, ...top5WithMore];

  // selected custom system
  const [ctmSystem, setCtmSystem] = useState<Option | null>();
  // keep custom systems
  const defaultSystems = getSavedCustomSystems() as Option[];
  const [customSystems, setCustomSystems] = useState<Option[]>(defaultSystems);

  /**
   * Select one system from more system list
   * @param value
   * @returns
   */
  const closeSystemView = (value: string) => {
    // close system view
    setIsNormalView(true);
    // trigger callback, to back to raw system select
    onSystemViewToggle && onSystemViewToggle(true);

    const systemInDisplay = mergedSystems.find(
      (system) => system.short === value,
    );
    // First: search in default list
    if (systemInDisplay) {
      setSystemBy(entityType, value);
      return onSystemSelect(systemInDisplay.short);
    }
    // Then: search in and compose more list
    const systemInOrigin = systemsByCategory.find(
      (system) => system.short === value,
    );
    if (systemInOrigin) {
      setPickedSystem([systemInOrigin, ...pickedSystem]);
      setSystemBy(entityType, value);
      return onSystemSelect(systemInOrigin.short);
    }

    // Last, create TKSystem from input
    const customTKSystem = createTKSystem(value, category);
    setPickedSystem([customTKSystem, ...pickedSystem]);
    setSystemBy(entityType, value);
    return onSystemSelect(customTKSystem.short);
  };

  /**
   * Create a new sytem and add it to the original list
   * @param newSystem
   */
  const customSystemCreatedHandler = (newSystemInput: string) => {
    const newOption = createOption(newSystemInput);
    setCustomSystems([newOption, ...customSystems]);
    setCtmSystem(newOption);
    // add to system list..
    closeSystemView(newSystemInput);
    // save it!
    saveCustomSystem(newSystemInput);
  };

  const customSystemChangeHandler = (sysOption: SingleValue<Option>) => {
    setCtmSystem(sysOption);
    const lastSystem = sysOption?.value;
    if (lastSystem) {
      closeSystemView(lastSystem);
    } else {
      console.warn('## selected empty custom system: ');
      console.warn(sysOption);
    }
  };

  const normalView = (classes: string) => {
    return clsx(classes, { hidden: !isNormalView });
  };

  const abNormalView = (classes: string) => {
    return clsx(classes, { hidden: isNormalView });
  };

  const selectSystemBy = (value: string) => {
    if (value === '*') {
      // hide original select, show more & custom systems
      onSystemViewToggle && onSystemViewToggle(false);
      // show more system grid
      return setIsNormalView(false);
    }
    onSystemSelect(value);
    setSystemBy(entityType, value);
  };

  /**
   * cache selected systems
   */
  useEffect(() => {
    if (!pickedSystem.length) return;
    // cache selected systems
    cacheSystemsBy(category, pickedSystem);
  }, [category, pickedSystem]);

  return {
    isNormalView,
    /** total system list by fitting type */
    systemsByCategory,
    /** System list displayed in dropwdown select! */
    mergedSystems,
    /** classes wrapper for non-more-systems display */
    normalView,
    /** classes wrapper for more-systems display */
    abNormalView,
    selectSystemBy,
    /** select a system from more list */
    closeSystemView,
    /** selected custom system */
    ctmSystem,
    /** switch custom system */
    customSystemChangeHandler,
    /** user added systems */
    customSystems,
    /** new sytem added */
    customSystemCreatedHandler,
  };
};
