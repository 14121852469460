import clsx from 'clsx';

import { CatalogueSubTotalGroup, SymbolSummaryRow } from '../types';

import { ExpandableSymbolGroup } from './expandable-symbol-group';

export const TakeoffCategoryGroup = ({ row }: { row: SymbolSummaryRow }) => (
  <div data-group={row.item_id_group} className="summary-row w-full bg-white">
    {/* Category Title */}
    {row.isCategory && (
      <>
        <h2 className="text-center font-medium text-base border-b border-gray-300 py-1">
          {row.item_id_group}
        </h2>
        <div className="w-full flex text-center border-b border-gray-300 font-medium">
          <span className="summary-report-cell w-72">Item ID</span>
          <span className="summary-report-cell w-20">System</span>
          <span className="summary-report-cell w-20">Quantity</span>
          <span className="summary-report-cell w-20">Units</span>
        </div>
      </>
    )}
    {/** row */}
    {!row.isCategory && (
      <div className="w-full flex text-center border-b border-gray-300">
        <span className="summary-report-cell w-72">{row.item_id_group}</span>
        <span className="summary-report-cell w-20">{row.system}</span>
        <span className="summary-report-cell w-20">{row.quantity}</span>
        <span className="summary-report-cell w-20">{row.units}</span>
      </div>
    )}
  </div>
);

export const TakeoffSubGroup = ({
  group,
  lazyCloseHandler,
}: {
  lazyCloseHandler: () => void;
  group: CatalogueSubTotalGroup;
}) => (
  <div className="subtotal-group p-1 w-full bg-white mb-6 relative">
    {/* PART_1: group header, could be fixed while close to the top */}
    <div className="sticky-group-header bg-white">
      {/* group title row */}
      <h2 className="text-center font-medium text-base border-b border-gray-300 py-1">
        {group.title}
      </h2>
      {/* grid fields row */}
      <div className="w-full flex text-center border-b border-gray-300">
        {group.fields.map((f, i) => (
          <span
            key={f}
            className={clsx(
              'inline-block p-1 border-r border-gray-300 text-xs overflow-x-hidden',
              i == 0 ? 'w-36' : 'w-20',
            )}
          >
            {f}
          </span>
        ))}
      </div>
    </div>
    {/* PART_2: group rows */}
    {group.groups.map((sg, i) => (
      <ExpandableSymbolGroup
        key={i}
        group={sg}
        dimensionFields={group.dimension}
        closeHandler={lazyCloseHandler}
      />
    ))}
    {/* PART_3: total row */}
    <div className="w-full flex text-center border-b border-gray-300 focus:outline-none">
      <span className="inline-block p-1 border-r border-gray-300 text-xs overflow-x-hidden w-36">
        Total
      </span>
      <span className="inline-block p-1 border-r border-gray-300 text-xs overflow-x-hidden w-20">
        {group.count}
      </span>
    </div>
  </div>
);
