/**
 * Handle Dom events from listens map definition
 *
 * @date 2024/04/08
 */
import * as _ from 'lodash-es';
import { MutableRefObject, useEffect } from 'react';

import { NullableBPMap, BPMapEditorAPI } from '../map/BPMapAPI';
import {
  BPSymbol,
  EventConfig,
  TKEvent as EVT,
  addEventHandler,
  addDomEventHandler,
  removeEventHandler,
  removeDomEventHandler,
} from '../types';

import { CommandListener as LSNR } from './domEventListeners';

/**
 * Update function context to `CommandListener` object!
 * NO NEED TO EXPORT THIS!
 * @param handler
 * @returns
 */
const binder = (handler: (param?: any) => void) => handler.bind(LSNR);

/**
 * to confirm selected feature in properties panel
 * @param featureId selected feature
 * @param silent if ignore updating properties panel, `false` in properties panel, `true` in review table
 */
export const onSingleFeatureConfirm = (featureId: string, silent: boolean) => {
  const mockEvt = { detail: { id: featureId, silent } };
  binder(LSNR.confirmSymbolHandler)(mockEvt);
};

/**
 * to confirm selected features
 */
export const onConfirmations = binder(LSNR.onConfirmations);

/**
 * to delete all the selected features from batch selection panel
 */
export const onDeleteAll = binder(LSNR.onDeleteAll);
/**
 * to copy selected feature from feature panel
 */
export const onCopy = binder(LSNR.onFeatureCopy);

/**
 * local use
 */
const blurHandler = binder(LSNR.onWindowBlur);
/**
 * local use
 */
const focusHandler = binder(LSNR.onWindowFocus);

const domEvents: EventConfig = {
  // === processing native events ===
  [EVT.KEYDOWN]: binder(LSNR.keydownHandler),
  [EVT.KEYUP]: binder(LSNR.keyupHandler),
  [EVT.BLUR]: binder(LSNR.keyupHandler),
  [EVT.CLICK]: binder(LSNR.onDomClickHandler),
  [EVT.CONTEXTMENU]: binder(LSNR.domContextMenuHandler),
  // processing map events
  [EVT.ZOOMOUT]: binder(LSNR.zoomOutHandler),
  [EVT.ZOOOMIN]: binder(LSNR.zoomInHandler),
  [EVT.ZOOMBY]: binder(LSNR.zoomByLevelHandler),
  // === apply brush filter tool
  [EVT.APPLY_BRUSH_SETTING]: binder(LSNR.applyBrushSettingHandler),
  // === add new fitting/equipment
  [EVT.NEWELEMENT]: binder(LSNR.newElementHandler),
  // === add new length/
  [EVT.NEWLINE]: binder(LSNR.newLineHandler),
  // update cache and feature properties panal
  // [EVT.CORRECTELEMENT]: binder(LSNR.correctElementHandler), // single correction
  [EVT.BATCHCORRECTION]: binder(LSNR.batchCorrectionHandler), // multiple correction
  [EVT.CORRECTSYMBOLSILENT]: binder(LSNR.correctSymbolSientHandler),
  [EVT.CONFIRMSYMBOL]: binder(LSNR.confirmSymbolHandler),
  [EVT.FEATUREDELETED]: binder(LSNR.onFeatureDelete), // *** delete feature from context menu & review panel! ***
  [EVT.MODIFYFEATUREMODE]: binder(LSNR.onFeatureModifyMode),
  [EVT.MOVEFEATUREMODE]: binder(LSNR.onFeatureMoveMode),
  [EVT.AUTOFEATUREMODE]: binder(LSNR.onFeatureAutoMode),
  [EVT.FLYTOFEATURE]: binder(LSNR.onReviewFittingFocus), // review fittings
  [EVT.FLYTOFEATUREONLEFT]: binder(LSNR.onReviewTotalSymbol), // review symbol in total correction table
  [EVT.UNCHECKFEATURE]: binder(LSNR.onClearCheckFeature),
  [EVT.RECHECKFEATURE]: binder(LSNR.onReCheckFeature),
  [EVT.FORCEUPDATEMAPSIZE]: binder(LSNR.onForceUpdateMap),
  [EVT.REVERSYMBOLS]: binder(LSNR.reverseFeatures),
  [EVT.REVERSESIZEDISPLAY]: binder(LSNR.reverseTextDisplay),
  [EVT.FILTERSYMBOLS]: binder(LSNR.filterFeaturesBy),
  [EVT.SYNC_SYMBOLS_FROM_TABLE]: binder(LSNR.showFeaturesBy),
  // highlight symbols with same system
  [EVT.HIGHLIGHTSYSTEM]: binder(LSNR.hightlightSymbolsBySystem),
  // zoom-in drawing on table view open
  [EVT.TOTAL_TABLE_OPENED]: binder(LSNR.zoomInDrawingAfterTable),
};

/**
 * Cleaner document event handlers
 *
 * @date 2024/04/08
 * @param ref
 * @param active
 * @param symbols all the symbols of current drawing cached in store
 * @param locked
 * @param pageId
 */
export const useDomEvents = (
  ref: MutableRefObject<NullableBPMap>, // ref of map is requried!
  active: boolean,
  symbols: BPSymbol[],
  locked: boolean,
  pageId: string,
  isReviewOpen: boolean,
) => {
  useEffect(() => {
    if (!ref.current) return;

    const map = ref.current as BPMapEditorAPI;
    // inject necessary drawing parameters(context)
    map.setMapEditLock(locked); // change lock mode
    map.setPageId(pageId);

    const watch = () => {
      _.forIn(domEvents, (handler, event) => {
        addDomEventHandler(event, handler);
      });
      addEventHandler(window, EVT.BLUR, blurHandler);
      addEventHandler(window, EVT.FOCUS, focusHandler);
    };

    const cleanup = () => {
      _.forIn(domEvents, (handler, event) => {
        removeDomEventHandler(event, handler);
      });
      LSNR.onDomClickHandler();
      removeEventHandler(window, EVT.BLUR, blurHandler);
      removeEventHandler(window, EVT.FOCUS, focusHandler);
    };

    if (active) {
      // register event listener.
      watch();
      // set context
      LSNR.setContext(ref.current, symbols, pageId, locked);
      LSNR.setReviewPanel(isReviewOpen);
    } else {
      // mute event listeners
      cleanup();
      LSNR.clearContext();
      LSNR.resetReviewPanel();
    }
    // *** CLEANUP TASK AFTER RERENDERING ***
    return cleanup;
  }, [ref, active, symbols, locked, pageId, isReviewOpen]);

  //
};
