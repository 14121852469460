import React, { useEffect, useRef } from 'react';

export type ClickAwayProps = {
  handleClickAway: () => void;
  classNames: string;
  children: React.ReactNode;
};

const ClickAwayHandler = ({
  handleClickAway,
  classNames,
  children,
}: ClickAwayProps) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    function handleOutSideClick(e: MouseEvent) {
      const node = e.target as Node;
      if (!ref.current || !ref.current.contains(node)) {
        handleClickAway();
      }
    }

    function handleEscKey(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        handleClickAway();
      }
    }

    window.addEventListener('click', handleOutSideClick);
    window.addEventListener('keydown', handleEscKey);

    return () => {
      window.removeEventListener('click', handleOutSideClick);
      window.removeEventListener('keydown', handleEscKey);
    };
  });

  return (
    <div className={classNames} ref={ref}>
      {children}
    </div>
  );
};

export default ClickAwayHandler;
