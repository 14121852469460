/**
 * Symbol statistics in summary modal to give user an overview of the drawing
 *
 * @2023/06/02
 */

import { useState, useEffect } from 'react';

import { calculateFloatCompletion } from '../state/builder';
import {
  BPSymbol,
  isFittingSymbol,
  isLengthSymbol,
  isEquipment,
} from '../types';

export const useSymbolStatistics = (symbols: BPSymbol[]) => {
  const [fittingCount, setFittingCount] = useState(0);
  const [lengthCount, setLengthCount] = useState(0);
  const [equipmentCount, setEquipmentCount] = useState(0);
  const [completionDegree, setCompletionDegree] = useState(0);

  useEffect(() => {
    const fittings = symbols.filter((smbl) => isFittingSymbol(smbl.category));
    const lengths = symbols.filter((smbl) => isLengthSymbol(smbl.category));
    const equipments = symbols.filter((smbl) =>
      isEquipment(smbl.category, smbl.type),
    );

    const completionInPercent = calculateFloatCompletion(symbols);
    const completionInNumber = parseFloat(completionInPercent);
    setFittingCount(fittings.length);
    setLengthCount(lengths.length);
    setEquipmentCount(equipments.length);
    setCompletionDegree(completionInNumber);
  }, [symbols]);

  // FIXME: limit it to 1 digit
  // @2024/03/27
  const inCompletionDegree = (100 - completionDegree).toFixed(1);

  return {
    fittingCount,
    lengthCount,
    equipmentCount,
    completionDegree,
    inCompletionDegree,
  };
};
