export * from './dom';
export * from './format';
export * from './lazy-import';
export * from './os';
export * from './memory';

import { toast } from 'react-toastify';

let _timeoutRef: NodeJS.Timeout | number = 0;

/**
 * Debounced timeout to execute last one only
 * @param fn delayed function
 * @date 2025/01/06
 */
export const debouncedTimeout = (fn: () => void) => {
  _timeoutRef && clearTimeout(_timeoutRef);
  _timeoutRef = setTimeout(fn, 300);
};

/**
 * Move the target element to the back of arry
 *
 * @param array any array
 * @param elementIndex postion to move back
 * @returns
 */
export const moveElementToBack = (array: any[], elementIndex: number) => {
  const aCopy = [...array];
  aCopy.push(aCopy.splice(elementIndex, 1)[0]);
  return aCopy;
};

/**
 * Move source element to the back of target element
 *
 * @param array any array
 * @param srcIndex source position
 * @param tarIndex target postion to put after it
 * @returns
 */
export const moveElementBackOF = (
  array: any[],
  srcIndex: number,
  tarIndex: number,
) => {
  const aCopy = [...array];
  const srcElement = aCopy.splice(srcIndex, 1)[0];
  aCopy.splice(tarIndex, 0, srcElement);
  return aCopy;
};

/**
 * Execute the first-in function
 * @param handler delayed function
 * @param timeout pending time to execute, 300 ms by default
 * @returns
 */
export const getThrottleFunction = (
  handler: (...args: unknown[]) => void,
  timeout = 300,
) => {
  let timerId: NodeJS.Timeout | null;
  return (...args: unknown[]) => {
    if (timerId) return;

    timerId = setTimeout(() => {
      handler.apply(this, args);
      timerId = null; // clear this task
    }, timeout);
  };
};

/**
 * Exectue the last-in function
 * @param handler
 * @param timeout
 * @returns
 */
export const getDebounceFunction = (
  handler: (...args: unknown[]) => void,
  timeout = 300,
) => {
  let timerId: NodeJS.Timeout;
  return (...args: unknown[]) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      handler.apply(this, args);
    }, timeout);
  };
};

/**
 * Debounce too many same popups occurrence at the short time!
 *
 * @param message string to popup
 * @param isError if the toast is error
 */
export const lazyNotify = getDebounceFunction(
  (msg: unknown, isError?: unknown) => {
    const options = { delay: 0, autoClose: 3000 };
    if (isError) {
      return toast.error(msg as string, options);
    }
    toast.info(msg as string, options);
  },
  500, // FIXME: bigger to reduce popups as possible - 2023/07/19
);
