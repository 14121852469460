import { useState, ChangeEvent } from 'react';

import { Commander, CommandListener } from '../bridge';

/**
 * logic for `filter-symbol-section` component
 * @date 2024/09/27
 * @param currentFilter global symbol filter
 * @param onFilterChange symbol filer callback
 */

export const useSymbolFilters = (
  currentFilter: string,
  onFilterChange: (filter: string) => void,
) => {
  const [inverseChecked, setInverseChecked] = useState(false);
  const [sizeHiddenChecked, setSizeHiddenChecked] = useState(true);

  const menuDropDownOpenHandler = () => {
    const selector = 'ul.nested-menu-dropdown';
    const menuItems = document.querySelector(selector) as HTMLElement;
    menuItems.classList.toggle('show');
    // dont change this time `100ms`, other cause dropdown stuck
    setTimeout(() => menuItems.classList.toggle('fade-in'), 100);
  };

  const closeDropdownMenuHandler = () => {
    const selector = 'ul.nested-menu-dropdown';
    const menuItems = document.querySelector(selector) as HTMLElement;
    // FIXME: could be `null` in solo mode
    // @2023/08/25
    if (!menuItems) return;
    menuItems.classList.remove('show');
    setTimeout(() => menuItems.classList.remove('fade-in'), 100);
  };

  /**
   * only display part of detections
   * all symbols: `FILTERS.ALL`
   * @param type
   * @returns
   */
  const handleFilterChange = (type: string) => {
    setInverseChecked(false);
    closeDropdownMenuHandler();
    // check filter if changed
    if (type === currentFilter) return; // no change, no filter
    onFilterChange(type);

    // FIXME: Cache sybmol fitler for review panel use!
    // @2024/09/26
    CommandListener.setSymbolFilter(type);

    // filter changed
    Commander.doFilterSymbols(type);
  };

  // if true, change all the boxes white
  const reverseCheckHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setInverseChecked(checked);
    Commander.doReverseSymbols(checked);
  };

  const toggleSizeDisplay = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setSizeHiddenChecked(checked);
    Commander.doToggleSizeText(checked);
  };

  return {
    sizeHiddenChecked,
    inverseChecked,
    handleFilterChange,
    reverseCheckHandler,
    toggleSizeDisplay,
    menuDropDownOpenHandler,
    closeDropdownMenuHandler,
  };
};
