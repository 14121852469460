import { useState, ChangeEvent, useEffect } from 'react';

import widthIcon from '@/assets/icons/width_icon.svg';

import { getSymbolDetailBy } from '../state';
import {
  TKEvent,
  CATALOGUE_TYPE,
  CATALOGUE_CATEGORY,
  LENGTH_TYPE as LT,
  FittingEntity,
} from '../types';
import { setDimensionBy, getDimensionBy } from '../types/dimension-setting';

export const useDuctLength = (
  lazyCloseHandler: (type?: string | undefined) => void,
) => {
  // actually system
  const [systemType, setSystemType] = useState('');
  const [shapeType, setShapeType] = useState('round');
  const [diameter, setDiameter] = useState('');
  const [height, setHeight] = useState('');
  const [width, setWidth] = useState('');

  const applyNewElementHandler = () => {
    lazyCloseHandler(LT.D);

    // FIXME: Save `width` value to `width_1` property!
    // @2023/01/31
    const extraProps =
      shapeType === 'round'
        ? { diameter_1: diameter }
        : { width_1: width, height_1: height };

    const detail: FittingEntity = {
      itemType: CATALOGUE_TYPE.L,
      category: CATALOGUE_CATEGORY.D,
      system: systemType.toUpperCase(),
      shape: shapeType,
      icon: widthIcon,
      entityType: LT.D,
      toolType: LT.D, // selected tool for editor check - @2023/12/20
      ...extraProps,
    };
    // lazy notification to map to add new element!
    setTimeout(() => {
      const event = new CustomEvent(TKEvent.NEWLINE, { detail });
      document.dispatchEvent(event);
    }, 200);

    // cache input fields for current duct type
    setDimensionBy(LT.D, {
      ...extraProps,
      shape: shapeType,
      system: systemType,
    });
  };

  const shapeChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setShapeType(event.target.value);
  };

  // lazy close, and dispatch cancel tool event...
  const cancelNewElementHandler = () => {
    lazyCloseHandler();
    const evt = new Event(TKEvent.CANCEL_DRAWING_TOOL);
    document.dispatchEvent(evt);
  };

  /**
   * Reset system for new length addition by using cached dimension
   */
  useEffect(() => {
    const dimension = getDimensionBy(LT.D);
    if (!dimension) return;

    dimension.shape && setShapeType(dimension.shape);
    dimension.height_1 && setHeight(dimension.height_1);
    dimension.width_1 && setWidth(dimension.width_1);
    dimension.diameter_1 && setDiameter(dimension.diameter_1);
  }, []);

  return {
    systemType,
    shapeType,
    diameter,
    width,
    height,
    setWidth,
    setHeight,
    setSystemType,
    applyNewElementHandler,
    shapeChangeHandler,
    setDiameter,
    cancelNewElementHandler,
  };
};
