import {
  STAT_FITTING_SYMOLS,
  STAT_LENGTH_SYMBOLS,
  STAT_EQUIPMENT_SYMBOLS,
  STAT_COMPLETE_DEGREE,
  STAT_INCOMPLETE_DEGREE,
} from '@/config';

import { BPSymbol } from '../types';

type StatRowProps = {
  label: string;
  value: string | number;
  suffix?: string;
};

const StatRow = ({ label, value, suffix }: StatRowProps) => (
  <li className="border-b border-gray-300 py-1 px-0 ">
    <span className="inline-block w-28 text-right">{label}</span>
    <span className="pl-2 text-sm font-bold">
      {value}
      {suffix || ''}
    </span>
  </li>
);

export const TakeoffStats = ({
  fittingCount,
  lengthCount,
  equipmentCount,
  completionDegree,
  inCompletionDegree,
  symbols,
}: {
  symbols: BPSymbol[];
  fittingCount: number;
  lengthCount: number;
  equipmentCount: number;
  completionDegree: number;
  inCompletionDegree: string;
}) => (
  <div className="left-panel w-56 bg-gray-100 border-r border-gray-300 py-2 pl-0 border-t">
    <h3 className="border-b-2 border-green-600 mb-2 py-1 text-center">
      Summary Statistics:
    </h3>
    <ul className="text-xs leading-5">
      <StatRow label="Symbols total:" value={symbols ? symbols.length : 0} />
      <StatRow label={STAT_FITTING_SYMOLS} value={fittingCount} />
      <StatRow label={STAT_LENGTH_SYMBOLS} value={lengthCount} />
      <StatRow label={STAT_EQUIPMENT_SYMBOLS} value={equipmentCount} />
      <StatRow
        label={STAT_COMPLETE_DEGREE}
        value={completionDegree}
        suffix="%"
      />
      <StatRow
        label={STAT_INCOMPLETE_DEGREE}
        value={inCompletionDegree}
        suffix="%"
      />
    </ul>
  </div>
);
