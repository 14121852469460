import { autoCloseNotification } from '@/stores/notifications';
import { writeException as warn } from '@/utils/logger';
import storage from '@/utils/storage';

import { HTTP_CODE, TOKENINVALIDCODES } from './http';
import { reportException } from './sentry';

/**
 * Axios instance response interceptor to handle system errors
 * @date 2024/11/05
 * @param error error object
 * @returns Promise
 */
export function authResponseFailureInterceptor(error: any) {
  const code = error.response?.status as number;
  warn('axios', `### got response code: ${code} ###`);

  const isTokenInvalid = TOKENINVALIDCODES.includes(code);
  if (isTokenInvalid) {
    warn('axios', '## got auth error to clear token ....');
    isTokenInvalid && storage.clearToken();
    // === decouple error handling the logic from low level tool
    // @2023/07/22
    const detail = HTTP_CODE[code];
    const eventType = detail.event;
    // FIXME: send error detail - @2024/11/05
    // to further discover the error!
    document.dispatchEvent(new CustomEvent(eventType, { detail }));
    // return error on token failure
    warn('axios', `## to return the error of code: ${code} `);
    return Promise.reject(error);
  }

  const errorDetail = error.response?.data?.detail; // this is what our system return
  const errorMessage = error.response?.data?.message; // or use message property
  const defaultError = error.message;
  const message = errorDetail || errorMessage || defaultError;
  // last step fallback to axios error notification ...
  // warn('axios', `>>> got error codoe: ${code}`);
  // warn('axios', `>>> to send error notification: `);
  // warn('axios', message);

  autoCloseNotification({
    type: 'error',
    title: 'Error',
    message,
  });
  // create a new error to report!
  const errObj = new Error(message);

  // global backend error broadcasting
  // @2024/10/25
  if (code == 500) {
    reportException(errObj);
  }
  // FIXME: throw error object finally! - @2024/09/16
  return Promise.reject(errObj);
}
